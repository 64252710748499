import { PostAPIService } from "context/services";
import React, { useState } from "react";
import { Button, notification } from "antd";
import { Link } from "react-router-dom";

const ApplyToJoinCard = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message: any, description: any) => {
    api.open({
      message: message,
      description: description,
      duration: 0,
    });
  };
  const [user, setUser]: any = useState({
    name: "",
    email: "",
    number: "",
    area: "",
    bedrooms: "",
    apartment: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const onChangeHandler = (key: any, value: any) => {
    let duplicate: any = { ...user };
    duplicate[key] = value;
    setUser(duplicate);
  };
  console.log("ffff", user);
  // handler payment
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    const payload = {
      full_name: user?.name,
      email: user?.email,
      phone_number: user?.number,
      area: user?.area,
      no_of_bedrooms: user?.bedrooms,
      apartment_type: user?.apartment,
      contact_message: user?.message,
    };
    setLoader(true);
    PostAPIService("/form/property_owner/submit", payload)
      .then((response) => {
        console.log("response", response);
        setLoader(false);
        setUser({
          name: "",
          email: "",
          number: "",
          area: "",
          bedrooms: "",
          apartment: "",
          message: "",
        });
        openNotification("Success", "Thank You our team will get back to you at the earliest.");
      })
      .catch((error) => {
        console.log("response", error);
        setLoader(false);
        openNotification("Error", "");
      });
  };

  return (
    <div className="relative z-[20]">
      {contextHolder}
      <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-4 lg:p-5 xl:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form
          className="space-y-4 md:space-y-6"
          onSubmit={(e) => onSubmitHandler(e)}
        >
          <h5 className="text-[21px] mb-2 font-medium text-gray-900 lg:!leading-[#3B393F]">
            Apply to join
          </h5>
          {/* <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="FirstName"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Last Name"
                required
                onChange={(e) => onChangeHandler("firstName", e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full ">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="LastName"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Last Name"
                required
                onChange={(e) => onChangeHandler("lastName", e.target.value)}
              />
            </div>
          </div> */}
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                Full Name*
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Name"
                required
                value={user?.name}
                onChange={(e) => onChangeHandler("name", e.target.value)}
              />
            </div>
            <div className="w-full md:w-1/2">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                Email Address*
              </label>
              <input
                type="email"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Email Address"
                required
                value={user?.email}
                onChange={(e) => onChangeHandler("email", e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                Phone number*
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Phone Number"
                required
                value={user?.number}
                onChange={(e) => onChangeHandler("number", e.target.value)}
              />
            </div>
            <div className="w-full md:w-1/2">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                Area*
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Area"
                required
                value={user?.area}
                onChange={(e) => onChangeHandler("area", e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-1/2">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                No of bedrooms*
              </label>
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Beds"
                required
                value={user?.bedrooms}
                onChange={(e) => onChangeHandler("bedrooms", e.target.value)}
              />
            </div>
            <div className="w-full md:w-1/2">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                Apartment type*
              </label>
              <select
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder="Apartment"
                value={user?.apartment}
                required
                onChange={(e) => onChangeHandler("apartment", e.target.value)}
              >
                <option>Select</option>
                <option value="Studio">Studio</option>
                <option value="Apartment">Apartment</option>
                <option value="Townhouse">Townhouse</option>
                <option value="Villa">Villa</option>
                <option value="Penthouse">Penthouse</option>
                <option value="Other">Any other</option>
              </select>
            </div>
          </div>

          {/* <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full">
              <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                How many weeks/year do you plan to rent a home with us?*
              </label>

              <input
                type="text"
                name=""
                id=""
                className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder=""
                required
                onChange={(e) => onChangeHandler("rentPlan", e.target.value)}
              />
            </div>
          </div> */}
          {/* <div>
            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
              How did you hear about Duomo?*
            </label>

            <input
              type="text"
              name=""
              id=""
              className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder=""
              required
              onChange={(e) => onChangeHandler("aboutDuomo", e.target.value)}
            />
          </div> */}
          <div>
            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
              How can we help you?
            </label>
            <textarea
              rows={6}
              className="bg-gray-50 border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Write something"
              value={user?.message}
              onChange={(e) => onChangeHandler("message", e.target.value)}
            />
          </div>
          <button
            disabled={loader}
            type="submit"
            className="w-full text-white bg-[#612ACE] hover:opacity-[0.9] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[15px] text-[16px] px-4 md:px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
          <div className="border-[1px] border-[#E9E8EA] mt-4">
            <div className="flex items-center">
              <div className="ps-3 pe-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                  />
                </svg>
              </div>
              <div className="border-l-[1px] mt-1 mb-1 ">
                <p className="ps-2 md:ps-4 pe-2 md:pe-4 text-[13px] text-[#535353]">
                  If you would like to discuss your home with us please contact
                  us at
                  <span className="border-b-[1px]"> info@myduomo.com</span>
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="text-sm font-[300] text-[#7B7B7F] text-center">
              Duomo processes the data collected to contact you and list your
              home on our website and other platforms. To learn more about the
              management of your personal data and to exercise your rights,
              please refer to our{" "}
              <Link to="/policy">
                <span className="border-b-[1px]">Privacy Policy</span>.{" "}
              </Link>
              {/* This site
              is protected by reCAPTCHA and the Google{" "}
              <span className="border-b-[1px]">Privacy Policy</span> and{" "}
              <span>Terms of Service</span> apply. */}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyToJoinCard;
