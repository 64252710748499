export const listings = [
    {
        "id": "1",
        "authorId": 0,
        "date": "",
        "href": " https://airbnb.ae/h/thegrand48",
        "booking": "https://www.booking.com/hotel/ae/luxurious-waterfront-view-2-bedroom-stylish-apt.html",
        "expedia": "https://www.expedia.com/Dubai-Hotels-Luxurious-Waterfront-View-2-Bedroom-Stylish-Apt.h105889670.Hotel-Information",
        "listingCategoryId": 1,
        "title": "2 Bedroom Dubai Creek Waterfront View Apartment",
        "featuredImage": "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        "galleryImgs": [
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/565935156.jpg?k=fe48c301731ccbd135694453abb593abec385380e45821639b660eb9381fa73c&o=",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/565935160.jpg?k=02a00d1c41c4f14944e4043196cf6f0780eb5483acbde9d0c9a709c3f50630b5&o=",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565938946.jpg?k=52c690209e6d4750a7595878444da2438786cee6f9e3a9f20fa0a934b9fb0fe4&o=&hp=1",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/565935142.jpg?k=90fb9c8964312a654f3b35902202eee2ec3c2552f22bb5e58d02146b427be838&o=",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/565935148.jpg?k=5a9ce93910478ae25fdb901394fee8a23408f52fdbf884612ba7510d264e0914&o=",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565935026.jpg?k=df3a22a9860888e9bc308f79c058a4589f8379f62509b4293c40b19a4d433df3&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565938641.jpg?k=9796170baf865c46e9d629518274e34233a818b2ad29c03af2a201a5efd4ad3b&o=&hp=1",
        ],
        "commentCount": 0,
        "viewCount": 0,
        "like": false,
        "address": "Ras Al Khor Road, Dubai, United Arab Emirates",
        "reviewStart": 0,
        "reviewCount": 0,
        "price": "$26",
        "maxGuests": 0,
        "bedrooms": 2,
        "bathrooms": 1,
        "saleOff": "",
        "isAds": true,
        "map": {
            "lat": 55.2094559,
            "lng": 61.5594641
        },
        "author": {
            "id": 0,
            "firstName": "",
            "lastName": "",
            "displayName": "",
            "email": "",
            "gender": "",
            "avatar": "/static/media/Image-10.ed67665686700f4b60ec.png",
            "count": 0,
            "href": "/",
            "desc": "",
            "jobName": "",
            "bgImage": ""
        },
        "listingCategory": {
            "id": 1,
            "name": "Entire room",
            "href": "",
            "thumbnail": "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
            "count": 0,
            "taxonomy": "category",
            "listingType": "stay"
        }
    },
    {
        "id": "2",
        "authorId": 0,
        "date": "",
        "href": "https://airbnb.ae/h/thegrand30",
        "booking": "https://www.booking.com/hotel/ae/stylish-2-bedroom-in-creek-harbour.html",
        "expedia": "https://www.expedia.com/Dubai-Hotels-Luxurious-Waterfront-View-2-Bedroom-Stylish-Apt.h105889670.Hotel-Information",
        "listingCategoryId": 1,
        "title": "2 Bedroom Family Apartment in Dubai Creek",
        "featuredImage": "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/561372207.jpg?k=48d1944c0eec86ca4fcf277db788c58efbfb3ba9ae1e8305317eef89acbc916f&o=&hp=1",
        "galleryImgs": [
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/561372207.jpg?k=48d1944c0eec86ca4fcf277db788c58efbfb3ba9ae1e8305317eef89acbc916f&o=&hp=1",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/562276815.jpg?k=6366153d1b27f7a0d8d29f6568e489c0ec4a29c478544762b9742f2f16bc89c2&o=",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/561371871.jpg?k=03e74aa4439b8f26580df7235d7dcd3d3b43c89f023891ff97d999d04ee3d86f&o=",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/561371871.jpg?k=03e74aa4439b8f26580df7235d7dcd3d3b43c89f023891ff97d999d04ee3d86f&o=",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/562276815.jpg?k=6366153d1b27f7a0d8d29f6568e489c0ec4a29c478544762b9742f2f16bc89c2&o=",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/562276803.jpg?k=f99b6d9bd56866e7ee6fb4394c06167deb352f153d8ef76dda410fa063a3dd9b&o=&hp=1",
            "https://cf.bstatic.com/xdata/images/hotel/max1024x768/562276792.jpg?k=07a024c1039ec957f98193f54deb7dfac79625e79aa48cd191b89769e671388b&o=",
        ],
        "commentCount": 0,
        "viewCount": 0,
        "like": false,
        "address": "Ras Al Khor Road The Grand Creek Harbour, Dubai, United Arab Emirates",
        "reviewStart": 0,
        "reviewCount": 0,
        "price": "$26",
        "maxGuests": 0,
        "bedrooms": 2,
        "bathrooms": 1,
        "saleOff": "",
        "isAds": true,
        "map": {
            "lat": 55.2094559,
            "lng": 61.5594641
        },
        "author": {
            "id": 0,
            "firstName": "",
            "lastName": "",
            "displayName": "",
            "email": "",
            "gender": "",
            "avatar": "/static/media/Image-10.ed67665686700f4b60ec.png",
            "count": 0,
            "href": "/",
            "desc": "",
            "jobName": "",
            "bgImage": ""
        },
        "listingCategory": {
            "id": 2,
            "name": "Entire room",
            "href": "",
            "thumbnail": "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
            "count": 0,
            "taxonomy": "category",
            "listingType": "stay"
        }
    },
    {
        "id": "3",
        "authorId": 0,
        "date": "",
        "href": "https://airbnb.ae/h/paramountdamac33",
        "booking": "https://www.booking.com/hotel/ae/myduomo-minimalist-chic-2-bedroom-in-paramount.html",
        "expedia": "",
        "listingCategoryId": 1,
        "title": "2 Bedroom Minimalist Apartment in Damac by Paramount",
        "featuredImage": "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687893.jpg?k=5d316db871b48684e8abad38db98fcc96a59d71a26825bf7aace1e96bf52be2e&o=&hp=1",
        "galleryImgs": [
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687893.jpg?k=5d316db871b48684e8abad38db98fcc96a59d71a26825bf7aace1e96bf52be2e&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687888.jpg?k=f07e265d560499b90cc7154da676811be4c7e34966d194030f10498a094ad828&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687701.jpg?k=950420ebe6f4d9f60804bcdfce3687458b31b0e17c25898ac1ae0f767d1241a3&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687644.jpg?k=9321827fa58ff821d09f6b118b6ed0a4ac6c2b530806cd73639a23ed34b07421&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687880.jpg?k=db99ddcb142d442945fed245c15c8697c23e04ef7e9c0cbb2280e52b25f5a883&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687873.jpg?k=8fd990c0333387aa5f454896aaa10146551ed93e7569524a6fdfe181805f2a42&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/574687846.jpg?k=a1628ff358a36baf21488a0d1c59c1ee193c5fda4d8bbf5e9f9f5ca085605334&o=&hp=1",
        ],
        "commentCount": 0,
        "viewCount": 0,
        "like": false,
        "address": "Damac Towers by Paramount, Dubai, United Arab Emirates",
        "reviewStart": 0,
        "reviewCount": 0,
        "price": "$26",
        "maxGuests": 0,
        "bedrooms": 2,
        "bathrooms": 1,
        "saleOff": "",
        "isAds": true,
        "map": {
            "lat": 55.2094559,
            "lng": 61.5594641
        },
        "author": {
            "id": 0,
            "firstName": "",
            "lastName": "",
            "displayName": "",
            "email": "",
            "gender": "",
            "avatar": "/static/media/Image-10.ed67665686700f4b60ec.png",
            "count": 0,
            "href": "/",
            "desc": "",
            "jobName": "",
            "bgImage": ""
        },
        "listingCategory": {
            "id": 5,
            "name": "Entire room",
            "href": "",
            "thumbnail": "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
            "count": 0,
            "taxonomy": "category",
            "listingType": "stay"
        }
    },
    {
        "id": "4",
        "authorId": 0,
        "date": "",
        "href": "https://airbnb.ae/h/standpoint22",
        "booking": "https://www.booking.com/hotel/ae/stylish-1-br-with-study-area-near-burj-khalifa.html",
        "expedia": "https://www.expedia.com/Dubai-Hotels-Stylish-Downton-1-BR-With-Study-Area-Near-Burj-Khalifa.h105986302.Hotel-Information",
        "listingCategoryId": 1,
        "title": "1 Bedroom with Study Nook Near Burj Khalifa",
        "featuredImage": "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234087.jpg?k=6a860710cb76da812dbdf295fd99b55dc815d7a31d6485e4fae27025ae2f64d2&o=&hp=1",
        "galleryImgs": [
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234087.jpg?k=6a860710cb76da812dbdf295fd99b55dc815d7a31d6485e4fae27025ae2f64d2&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234181.jpg?k=091c2c8ba0fc2ab5e7e7d193b0c4fb138361aaa733998846c8d02350adeaaae6&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234241.jpg?k=f5b32a01f00ce487dc2620e88d630daa4768a5fa25a7d773c923676bcfa9b698&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234210.jpg?k=2c3d86cc7ac8130bcdc5c28e88015a9d2eef9605e4831577c7a62743075e7bfd&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234187.jpg?k=775cf08a17b85ddf2643b4fdf2555ba48f4e78b209adf457698c4b460114af72&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234197.jpg?k=65c70d6aab479cce891b71ca148989b05f28d37517d1deacfc9096ca665c63ba&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/565234220.jpg?k=db358593b09e6835f642c103a2de8ff1e25ef2cfe78d02a9714333f374d9cc22&o=&hp=1",
        ],
        "commentCount": 0,
        "viewCount": 0,
        "like": false,
        "address": "Standpoint Tower A, Downtown Dubai, Dubai, United Arab Emirates",
        "reviewStart": 0,
        "reviewCount": 0,
        "price": "$26",
        "maxGuests": 0,
        "bedrooms": 1,
        "bathrooms": 1,
        "saleOff": "",
        "isAds": true,
        "map": {
            "lat": 55.2094559,
            "lng": 61.5594641
        },
        "author": {
            "id": 0,
            "firstName": "",
            "lastName": "",
            "displayName": "",
            "email": "",
            "gender": "",
            "avatar": "/static/media/Image-10.ed67665686700f4b60ec.png",
            "count": 0,
            "href": "/",
            "desc": "",
            "jobName": "",
            "bgImage": ""
        },
        "listingCategory": {
            "id": 3,
            "name": "Entire room",
            "href": "",
            "thumbnail": "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
            "count": 0,
            "taxonomy": "category",
            "listingType": "stay"
        }
    },
    {
        "id": "5",
        "authorId": 0,
        "date": "",
        "href": "https://airbnb.ae/h/harbourviewt2",
        "booking": "https://www.booking.com/hotel/ae/myduomo-harbourfront-2-bedroom-apartment.html",
        "expedia": "https://www.expedia.com/Dubai-Hotels-MyDuomo-2-Bedroom-Harbour-View-In-Dubai-Creek.h106309910.Hotel-Information",
        "listingCategoryId": 1,
        "title": "2 Bedroom Harbour Elegant Apartment in Dubai Creek",
        "featuredImage": "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097068.jpg?k=31c3e6cd3f0f8c5ceb21548d1851c2065e1d34bb7e6e4d84b263cc514bec873b&o=&hp=1",
        "galleryImgs": [
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097068.jpg?k=31c3e6cd3f0f8c5ceb21548d1851c2065e1d34bb7e6e4d84b263cc514bec873b&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097094.jpg?k=5ad0bde83645e963550160db47f59d62ab6634d0cb1a15d6bf38cc4fa932d7fa&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572096882.jpg?k=a366351d9e8efacac6d2e61bc1a1f760358c991e33201b3d876ee2d3c5a420c3&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097042.jpg?k=7a6f95fa552c1c8016c65d6ebbb5f4e41a2bbbaa038a57257a87aa611c4c1f14&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097055.jpg?k=a44f464daece4e1ecd794f3310d47dc09ff25d2ec25f838880a9ab1c5712cd37&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097075.jpg?k=ebb599fa6dea236baaad17cb379ec89426b0fba57f3dd9949924c0abbe49c152&o=&hp=1",
            "https://cf2.bstatic.com/xdata/images/hotel/max1024x768/572097070.jpg?k=ef7c3db02d476ff06dfc6ee11d60dad76e78fad96999ee9bbd064929d5f8a1b1&o=&hp=1",
        ],
        "commentCount": 0,
        "viewCount": 0,
        "like": false,
        "address": "Ras Al Khor Road, Dubai, United Arab Emirates",
        "reviewStart": 0,
        "reviewCount": 0,
        "price": "$26",
        "maxGuests": 0,
        "bedrooms": 2,
        "bathrooms": 1,
        "saleOff": "",
        "isAds": true,
        "map": {
            "lat": 55.2094559,
            "lng": 61.5594641
        },
        "author": {
            "id": 0,
            "firstName": "",
            "lastName": "",
            "displayName": "",
            "email": "",
            "gender": "",
            "avatar": "/static/media/Image-10.ed67665686700f4b60ec.png",
            "count": 0,
            "href": "/",
            "desc": "",
            "jobName": "",
            "bgImage": ""
        },
        "listingCategory": {
            "id": 4,
            "name": "Entire room",
            "href": "",
            "thumbnail": "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
            "count": 0,
            "taxonomy": "category",
            "listingType": "stay"
        }
    },
  
]