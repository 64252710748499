import React, { useState } from "react";
import TravelLogo1 from "images/aboutUs/partner1.png";
import TravelLogo2 from "images/aboutUs/partner2.png";

const TravelAboutUs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const travelers = [
    {
      id: 1,
      title: "Mission",
      content:
        "At Duomo's, our mission is to offer unmatched holiday home management services that surpass the expectations of our clients. We are committed to providing an exceptional experience for both property owners and guests, ensuring peace of mind, comfort, and memorable stays. We achieve this through innovation, integrity, and personalized service. We aim to set the standard in the holiday home management industry, cultivating trust and building long term partnership with our valued clients.",
      img: require("images/aboutUs/mission.png"),
    },
    {
      id: 2,
      title: "Vision",
      content:
        "Aspiring to become the top choice in holiday home management, we envision a future where Duomo represents excellence, reliability, and innovation. We aim to consistently elevate the excellence of our services, incorporating state-of-the-art technologies and sustainable approaches that will make us the number one, holiday home go-to company in the Gulf Region.",
      img: require("images/aboutUs/vision.png"),
    },
  ];
  return (
    <div>
      <div className="bg-[#FCFBFF] py-20 mt-5 md:mt-0">
        <div>
          <div>
            <h4 className="pe-4 text-[23px] font-[600] text-[#4248ED] pt-2 text-center">
              My Duomo
            </h4>
          </div>
          {/* links */}
          <div className="hidden lg:block">
            <div className="flex justify-center pt-12">
              <div className="mx-[15%] xl:mx-[22%] aboutus-list-container 2xl:px-10">
                <ul className="flex items-center justify-center space-x-40">
                  <li
                    className={`text-[19px] cursor-pointer text-[#000000] border-b-[4px] pb-3 hover:border-[#4248ED] hover:font-[600]
                  ${
                    selectedTab === 0
                      ? "font-[600] border-[#4248ED]"
                      : "font-[400] border-[#FCFBFF]"
                  }
                  `}
                    onClick={() => setSelectedTab(0)}
                  >
                    Mission
                  </li>
                  <li
                    className={`text-[19px] cursor-pointer text-[#000000] border-b-[4px] pb-3 hover:border-[#4248ED] hover:font-[600]
                  ${
                    selectedTab === 1
                      ? "font-[600] border-[#4248ED]"
                      : "font-[400] border-[#FCFBFF]"
                  }
                  `}
                    onClick={() => setSelectedTab(1)}
                  >
                    Vision
                  </li>
                </ul>
                {/* content */}
                <div className="flex pt-16">
                  <div className="w-[25%]">
                    <img
                      src={travelers[selectedTab]?.img}
                      alt=""
                      className="rounded-full"
                    />
                  </div>
                  <div className="w-[75%] pl-12 pt-5">
                    <h2 className="text-[30px] font-[600]">
                      {travelers[selectedTab]?.title}
                    </h2>
                    <p className="pt-5 text-[15px] font-[400] leading-[25px]">
                      {travelers[selectedTab]?.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            {travelers?.map((item, index) => (
              <div className="flex flex-col items-center pt-16" key={index}>
                <div className="w-[50%] md:w-[25%]">
                  <img src={item?.img} alt="" />
                </div>
                <div className="w-[90%] md:w-[75%] pt-8">
                  <h2 className="text-[30px] font-[600] text-center">
                    {item?.title}
                  </h2>
                  <p className="pt-5 text-[15px] font-[400] leading-[25px] text-center">
                    {item?.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelAboutUs;
