import React, { FC } from "react";
import MainNav1 from "./MainNav1";
import ListeningHeader from "./ListingHeader";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";

export interface HeaderProps {}

const Header: FC<HeaderProps> = (props: any) => {
  const { history } = props;
  const [isTop, setisTop] = useState(true);

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    const $head = document.getElementById("nc-chifis-header");
    if (!$head) return;
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      !!isTop && setisTop(false);
    } else {
      setisTop(true);
    }
  }

  // handler to show header
  const activeHeaderHandler = (pathname: string) => {
    switch (pathname) {
      case "/listings":
        return <ListeningHeader isTop={isTop} />;
      default:
        return <MainNav1 isTop={isTop} />;
    }
  };

  return (
    <div
      id="nc-chifis-header"
      className="nc-Header lg:sticky lg:top-0 w-full lg:left-0 lg:right-0 z-40"
    >
      <Helmet>
        <title>DUOMO</title>
      </Helmet>
      {/* NAV */}
      {activeHeaderHandler(history?.location.pathname)}
    </div>
  );
};

export default withRouter(Header);
