import React, { FC } from "react";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import ReactPlayer from "react-player/lazy";
// import Placeholder from "images/Placeholder.png";

export interface SectionHeroInterface {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionHeroInterface> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/duomohomepage.mp4";
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="w-full">
        <div className="w-full ">
          <ReactPlayer
            url={video}
            controls={false}
            loop
            playing
            playsinline={true}
            muted={true}
            className="!w-[100%] banner-homevideo video-container-home"
          />
        </div>
        <div className="z-10 w-full lg:mt-[-80px] flex justify-center lg:px-10 2xl:px-28">
          <HeroSearchForm />
        </div>
      </div>{" "}
    </div>
  );
};

export default SectionBecomeAnAuthor;
