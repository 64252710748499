import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";
import { withRouter } from "react-router-dom";

const PageAbout = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative `}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <div className="container py-16 lg:py-20 space-y-16 lg:space-y-28">
        <SectionHero />
      </div>
    </div>
  );
};

export default withRouter(PageAbout);
