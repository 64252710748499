import React, { FC } from "react";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionGridHasMap from "./SectionGridHasMap";
import Filters from "./TabFilters";
import { Helmet } from "react-helmet";

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>Duomo Tourism</title>
      </Helmet>
      <BgGlassmorphism />

      {/* SECTION */}
      <div className="mx-[3%] lg:container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none pt-16 lg:pt-20">
        <div className="block lg:hidden hero-form-container">
          <HeroSearchForm currentPage="Stay" currentTab="Stay" />
        </div>{" "}
        <SectionGridHasMap />
      </div>
    </div>
  );
};

export default ListingStayMapPage;
