import React, { useState } from "react";
import { FC } from "react";
import AnimateHeight from "react-animate-height";
import Img from "images/place/dubai-ma.jpg";
import { Link } from "react-router-dom";

interface FAQInterfaces {
  className?: string;
  faqPage?: string;
}

const Comment: FC<FAQInterfaces> = ({ className, faqPage }) => {
  const [height, setHeight]: any = useState(0);

  const HomeFaqs = [
    {
      id: 1,
      name: "How it works Why Duomo?",
      answere:
        "We unlock the full potential of your property's short-term rentals worldwide with our comprehensive management services. From crafting enticing online listings and professional photography to seamless pricing management       and guest communication, we handle it all. Elevate your income while minimizing your workload – we take care of every aspect, from cleaning and laundry to providing toiletries and ensuring smooth guest check-ins. Explore stress-free property management with us!",
    },
    {
      id: 2,
      name: "How we Optimize Your Home's Occupancy for Maximum Returns?",
      answere:
        "Elevate your rental income with our dedicated team of hospitality experts. We've meticulously designed a comprehensive pricing strategy,       considering key factors like city-wide occupancy rates, travel trends,       property location, events, seasonality, competitor pricing, and more. Rely       on our expertise to ensure that your rental property consistently achieves       its highest revenue potential throughout the year.",
    },
    {
      id: 3,
      name: " How Does Duomo Provide Peace of Mind by Handling Damages with Care?",
      answere:
        "At Duomo, our meticulous guest vetting process guarantees that your       home is entrusted to responsible guests. Rigorous inspections before and after       each stay are standard. In the rare event of damage, we take charge, managing       all aspects to swiftly correct the situation. Rest assured, Airbnb's Host Guarantee       provides global protection for hosts in case of damages during a stay.       Additionally, our tailored policies, including guest deposits and extra local       insurance  further mitigate any potential risks",
    },
    {
      id: 4,
      name: " How to unlock Your Property's Earning Potential?",
      answere:
        "Curious about the income potential of your short-term rental? Our team will provide  the most accurate estimate proposal. Your property's  earning capacity is influenced by its location and the number of bedrooms       it features. Discover the possibilities and maximize your short-term rental       returns with our tailored calculation tool.",
    },
    {
      id: 5,
      name: "Can You Stay In Control and Track Your Bookings and Revenue with Ease?",
      answere:
        "Description: Absolutely! With your property listed and ready for bookings, we provide exclusive access to our user-friendly online       dashboard. Keep a close eye on your bookings and earnings effortlessly,       ensuring you're always in the know about your property's performance.",
    },
    {
      id: 6,
      name: "How Does Duomo Facilitate Exceptional Stays in Short-Term Rentals?",
      answere:
        "Duomo caters to guests in search of short-term furnished       apartments and villas, available on a daily, weekly, or monthly basis.       Elevate the guest experience with our luxurious offerings, featuring       convenient self check-ins and check-outs, personalized concierge services,      and the flexibility to customize stays to their preferences",
    },
  ];

  const Faqs = [
    {
      id: 1,
      name: "Who Covers Cleaning and Maintenance Costs for Your Property?",
      answere:
        "At Duomo, guests shoulder cleaning costs unless the owner stays at the property, in which case, the owner covers it. Routine maintenance, a standard part of property upkeep, is the owner's responsibility.  Exceptions apply if guest-caused damages necessitate maintenance,       ensuring your property is always in top-notch condition.",
    },
    {
      id: 2,
      name: " Is our operation team qualified?",
      answere:
        "To maintain the highest standards, we operate distinct Cleaning and Maintenance companies, each fully licensed and staffed with       professionals trained the Duomo way. We prioritize in-house cleaning       services, avoiding outsourcing, and selectively outsource specialized       maintenance tasks. Our dedicated teams undergo role-specific training,       ensuring expertise and excellence in every aspect of property care",
    },
    {
      id: 3,
      name: "Can I Have More Involvement in the Interior Design Process?",
      answere:
        "While our packages are designed for efficiency, we've reserved a       more collaborative Interior Design process exclusively for our Premium       Interior Design Services. With our standard packages, decisions are       streamlined to expedite property readiness and profitability. Explore       enhanced involvement options with our premium offering for a       personalized touch",
    },
    {
      id: 4,
      name: "How does Duomo manage the listings of my property       effectively?",
      answere:
        "At Duomo, we employ a meticulous approach to listing       management. Our team optimizes your property listings across various       platforms, ensuring they are not only visually appealing but also       strategically positioned for maximum visibility. From crafting compelling       descriptions to using high-quality images, we focus on making your       property stand out in the competitive short-term rental market.",
    },
    {
      id: 5,
      name: "What if something gets damaged by your team during cleaning       or maintenance?",
      answere:
        "Rest easy with Duomo's commitment to property care. In the rare       event that any item is accidentally damaged during our cleaning process, we take full responsibility. Our team ensures swift resolution by either       fixing or replacing the item at no charge to the property owner. Your peace      of mind is our top priority.",
    },
    {
      id: 6,
      name: "How Duomo deals with emergency? Maintenance or cleaning?",
      answere:
        "At Duomo, our commitment is to address maintenance concerns       swiftly. For minor issues, our team is on standby in key Dubai areas for immediate call-outs, aiming to resolve them within a few hours. Larger       problems, dependent on parts, may take slightly longer, but our goal is to       fix all issues, major or minor, within 24 hours for your peace of mind",
    },
  ];

  const Corporate = [
    {
      id: 1,
      name: "What is Duomo's Commercial Affiliates Program ?",
      answere:
        "Elevate your business accommodations with our specialized Corporate Partners Program. Tailored for businesses and organizations, this exclusive offering provides high-quality and cost-effective accommodations for your employees or clients. Discover a new level of comfort and convenience for your corporate stays.",
    },
    {
      id: 2,
      name: "What Benefits Await You in Unlocking Duomo's Corporate Affiliate Program?",
      answere:
        "As a corporate partner, you enjoy exclusive access to a diverse portfolio of meticulously managed properties. Tailor accommodations to your specific needs, offering unmatched comfort, cost-efficiency, and flexibility for your valued employees or clients",
    },
    {
      id: 3,
      name: "Ensuring Safety: What Measures Are in Place for Corporate Guests?",
      answere:
        "At Duomo, your safety is our priority. Our commitment to stringent safety and security standards includes thorough guest screening and clear guidelines for property behavior. Rest assured, these measures are in place to ensure the well-being of all our valued corporate guests",
    },
    {
      id: 4,
      name: "Wondering How to Start as a Corporate Partner with Duomo?",
      answere:
        "Getting started is easy – simply reach out to our dedicated Corporate Partners team. Share your specific accommodation needs and preferences, and we'll collaborate with you to tailor a partnership that perfectly suits the requirements of your organization.",
    },
  ];

  const FAQHandler = (page?: any) => {
    switch (page) {
      case "Home":
        return HomeFaqs;
      case "Corporate":
        return Corporate;
      default:
        return Faqs;
    }
  };

  return (
    <div className={`nc-FAQ ${className}`}>
      <div>
        <h6 className="font-[500] text-[12px] text-[#777E90] text-center">
          Learn how to get started
        </h6>
        <h1 className="text-[#23262F] font-[500] text-center text-[40px] lg:text-[56px] lg:py-4 relative leading-[50px]">
          Frequently Asked Questions
        </h1>
        <p className="text-[16px] text-center pt-6 text-[#353945]">
          Join community now to get free updates and also alot of freebies are
          waiting for you or{" "}
          <Link to="/contact">
            <span className="text-[#3772FF]">Contact Support</span>
          </Link>
        </p>
      </div>
      {/*  */}
      {/* <div className="w-full pt-12 pb-9">
        <div className="flex flex-wrap justify-center px-5 lg:px-0">
          <button className="border border-[#353945] bg-[#353945] text-white rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] font-[500]">
            Dubai
          </button>
          <button className="border-white border hover:border-[#353945] hover:bg-[#353945] hover:text-white text-[#777E90] rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3">
            Booking
          </button>
          <button className="border-white border hover:border-[#353945] hover:bg-[#353945] hover:text-white text-[#777E90] rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3">
            Hosting
          </button>
          <button className="border-white border hover:border-[#353945] hover:bg-[#353945] hover:text-white text-[#777E90] rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3">
            Support
          </button>
        </div>
      </div> */}
      {/* collapsible */}
      <div className="pt-16">
        {FAQHandler(faqPage).map((item) => (
          <div className="border-t" key={item.id}>
            <div
              className="flex justify-between items-center py-6 cursor-pointer"
              aria-expanded={height !== 0}
              aria-controls="example-panel"
              onClick={() =>
                item.id === height ? setHeight(0) : setHeight(item.id)
              }
            >
              <h1 className="text-[#23262F] text-[500] text-center text-[16px] font-[500]">
                {item.name}
              </h1>
              <button
                className={` left-button bg-white dark:bg-neutral-900 dark:hover:border-neutral-500 text-[#777E90] rounded-full flex items-center justify-center hover:border-neutral-300 transition ease-in-out delay-150 duration-300
                ${item.id === height ? "rotate-[90deg] " : "rotate-[-90deg] "}
                `}
                title="Prev"
                data-glide-dir="<"
              >
                <i className="las la-angle-left"></i>
              </button>
            </div>
            <AnimateHeight
              id="example-panel"
              duration={500}
              height={item.id === height ? "auto" : 0} // see props documentation below
            >
              {" "}
              <div className="flex flex-col lg:flex-row justify-between pb-6">
                {/* <div className="w-full lg:w-[40%]">
                  <div
                    className="w-full lg:w-[60%]"
                    style={{
                      background: `url(${Img})`,
                      height: "320px",
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></div>
                </div> */}
                <div className="w-full">
                  {/* <h6 className="text-[16px] text-left pt-6 text-[#23262F] font-[500]">
                    You can only cancel the booking and get a refund up to 24
                    hours after paying for the room.
                  </h6> */}
                  <div className="">
                    <p className="text-[16px] text-left text-[#777E90] font-[500] py-1">
                      {item.answere}
                    </p>
                    {/* <p className="text-[16px] text-left text-[#777E90] font-[500] py-1">
                      2. Select the room you want to cancel
                    </p>
                    <p className="text-[16px] text-left text-[#777E90] font-[500] py-1">
                      3. Click "Cancel Booking";
                    </p>
                    <p className="text-[16px] text-left text-[#777E90] font-[500] py-1">
                      4. Confirm the refunded amount and click "Confirm".
                    </p> */}
                  </div>
                  {/* <div>
                    <button className="border border hover:border-[#353945] hover:bg-[#353945] hover:text-white text-[#777E90] rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3">
                      Learn more
                    </button>
                  </div> */}
                </div>
              </div>
            </AnimateHeight>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comment;
