import React, { FC, useEffect, useRef, useState } from "react";
import OurServicesMain from "../../components/Services/OurServices";
import ServicesDetails from "../../components/Services/ServicesDetails";
import PropertRefrelSection from "components/PropertyOwner/PropertyReferlFreind";
import FAQ from "shared/Faq/Faq";
import ReferFriendModal from "components/Services/ReferFriendModal";
import { withRouter } from "react-router";

const OurServics = (props: any) => {
  const ref: any = useRef(null);
  const { history } = props;
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  useEffect(() => {
    if (history?.location?.search?.split("=")[1] === "true") {
      handleClick();
    }
  }, [history?.location?.search?.split("=")[1]]);
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id="PageAbout"
    >
      <ReferFriendModal
        isOpenMoreFilter={isOpenMoreFilter}
        setisOpenMoreFilter={setisOpenMoreFilter}
      />
      <div>
        <OurServicesMain />
      </div>
      <div>
        <ServicesDetails />
      </div>
      {/* <div ref={ref}>
        <PropertRefrelSection setisOpenMoreFilter={setisOpenMoreFilter} />
      </div> */}
      {/* <div className="mx-[3%] lg:mx-[12%] 2xl:mx-[10%] py-10">
        <FAQ />
      </div> */}
    </div>
  );
};

export default withRouter(OurServics);
