import React from "react";
// import clientSay1 from "images/place/Tour.png";
import ReactPlayer from "react-player/lazy";

const OurServicesMain = () => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/duomoservice.mp4";
  return (
    <div className="w-full">
      <div className="">
        {/* <img className="w-full " src={clientSay1} alt="" /> */}
        <ReactPlayer
          url={video}
          controls={false}
          loop
          playing
          playsinline={true}
          muted={true}
          className="!w-[100%] banner-servicevideo"
        />
      </div>
      <div className="w-full h-full flex items-center justify-center">
        <div className="bg-[#fff] px-4 sm:px-6 lg:px-8 pt-12 pb-12 md:pb-20 rounded-[6px] w-full md:w-2/3 lg:w-[60%] 2xl:w-[50%] relative z-10 md:bottom-[130px] flex flex-col justify-center">
          <div>
            <h1 className="text-[40px] font-[600]">Our Services</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServicesMain;
