import rightImg from "images/about.png";
import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionHero from "./SectionHero";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import HelpCenterHeroimg from "components/HelpCenter/HelpCenterHeroimg";
import FAQ from "components/HelpCenter/DetailsHelpCenter";
import Travelers from "components/HelpCenter/Travelers";

export interface PageAboutProps {
  className?: string;
}
const AboutPage: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <HelpCenterHeroimg />
      <div className="mx-[3%] lg:mx-[3%] 2xl:mx-[15%] pb-10 md:mt-[-100px] z-[30] relative">
        <FAQ />
      </div>
      <div className="pb-20">
        <Travelers />
      </div>
      {/* <DetailsHelpCenter /> */}
    </div>
  );
};

export default AboutPage;
