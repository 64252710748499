import React, { useState } from "react";
import { FC } from "react";
import AnimateHeight from "react-animate-height";
import Img from "images/place/dubai-ma.jpg";

interface FAQInterfaces {
  className?: string;
}

const Comment: FC<FAQInterfaces> = ({ className }) => {
  const [height, setHeight]: any = useState(0);
  const Faqs = [
    {
      id: 1,
      name: "How much can I make from my property?",
      answere:
        "Number of bedrooms, the demand and the occupancy rate are the major factors to be taken into consideration while generating a revenue forecast. Our team of experienced professionals will provide you with the most accurate revenue estimate for your property.",
    },
    {
      id: 2,
      name: "Can I list the property on my own on Duomo website?",
      answere:
        "This option is currently unavailable. Contact us on +971 50 1510743, and we will assist you in making the necessary arrangements. We will help you list the property on DUOMO as well as other platforms.",
    },
    {
      id: 3,
      name: " If my apartment is unfurnished, can I still list it with you?",
      answere:
        "At Duomo, we take care of the furnishing and styling your property. Contact us on +971 50 1510743 for further information.",
    },
    {
      id: 4,
      name: "What happens if something is damaged during a guest’s stay?",
      answere:
        "Our team ensures complete screening of the guests and property inspection before and after each stay. In the event of any damage, we will handle all aspects of maintenance. We also have a guest deposit policy to mitigate any such damage related situations.",
    },
  ];
  return (
    <div className={`nc-FAQ ${className} bg-[#F6F8F9] py-20`}>
      <div className="flex flex-col md:flex-row justify-between mx-[3%] md:mx-[5%]">
        <div className="md:pr-5">
          <h1 className="text-[#23262F] font-[600] text-center md:text-left text-[30px] lg:text-[35px] 2xl:text-[40px] relative leading-[44px] ">
            Your questions, answered
          </h1>
        </div>
        {/* collapsible */}
        <div className="w-full md:w-[55%] mt-16 md:mt-0">
          {Faqs.map((item) => (
            <div className="border-t" key={item.id}>
              <div
                className="flex justify-between items-center py-6 cursor-pointer"
                aria-expanded={height !== 0}
                aria-controls="example-panel"
                onClick={() =>
                  item.id === height ? setHeight(0) : setHeight(item.id)
                }
              >
                <h1 className="text-[#23262F] text-[500] text-left text-[16px] font-[500]">
                  {item.name}
                </h1>
                <button
                  className={` left-button dark:bg-neutral-900 dark:hover:border-neutral-500 text-[#777E90] rounded-full flex items-center justify-center hover:border-neutral-300 transition ease-in-out delay-150 duration-300
                ${item.id === height ? "rotate-[90deg] " : "rotate-[-90deg] "}
                `}
                  title="Prev"
                  data-glide-dir="<"
                >
                  <i className="las la-angle-left"></i>
                </button>
              </div>
              <AnimateHeight
                id="example-panel"
                duration={500}
                height={item.id === height ? "auto" : 0} // see props documentation below
              >
                {" "}
                <div className="flex flex-col lg:flex-row justify-between pb-6">
                  <div className="w-full">
                    <div className="">
                      <p className="text-[16px] text-left text-[#777E90] font-[500] py-1">
                        {item.answere}
                      </p>
                    </div>
                  </div>
                </div>
              </AnimateHeight>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comment;
