import React, { FC } from "react";
const CardCategory3 = () => {
  return (
    <div className="py-10 lg:py-20 mx-[3%] lg:mx-[5%]">
      <div className="bg-[#F6F8F9] py-10 lg:py-28 ">
        <div className="mx-[5%] lg:mx-[7%] 2xl:mx-[9%]">
          <div>
            <h2 className="text-[#000000] text-[30px] font-[500] text-center">
              How it works
            </h2>
          </div>
          <div className="flex flex-wrap lg:flex-col justify-between pt-20 w-full">
            {/* 1 */}
            <div className="flex flex-col-reverse lg:flex-row relative w-[48%] lg:w-full h-full">
              <div className="flex items-center w-full lg:w-[50%] lg:border-r-2 lg:border-dotted">
                <div className="w-full lg:w-[70%]">
                  <p className="text-[14px] lg:text-[21px] text-[#000000] font-[500] text-center lg:text-left w-full lg:pl-10">
                    Enquire: Contact us and submit the property for review.
                  </p>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="absolute w-8 h-8 rounded-full bg-[#612ACE] left-[50%] right-[50%] top-[50%] ml-[-18px] mt-[-20px]"></div>
              </div>
              <div className="flex justify-center items-center w-full lg:w-[50%] lg:justify-end">
                <div className="w-full lg:w-[70%] mb-4 lg:mb-0">
                  <img
                    src={require("images/listProperty/submit1.png")}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="flex flex-col lg:flex-row relative w-[48%] lg:w-full h-full">
              <div className="flex justify-center items-center w-full lg:w-[50%] lg:justify-start lg:border-r-2 lg:border-dotted">
                <div className="w-full lg:w-[70%] mb-4 lg:mb-0 lg:pt-16">
                  <img src={require("images/listProperty/sign.png")} alt="" loading="lazy"/>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="absolute w-8 h-8 rounded-full bg-[#612ACE] left-[50%] right-[50%] top-[50%] ml-[-18px] mt-[-20px]"></div>
              </div>
              <div className="flex items-center w-full lg:w-[50%] lg:justify-end">
                <div className="w-full lg:w-[70%]">
                  <p className="text-[14px] lg:text-[21px] text-[#000000] font-[500] text-center lg:text-left w-full lg:pl-10">
                    Signing contract & registration: Our team begins onboarding
                    including property registration with DET
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="flex flex-col-reverse lg:flex-row relative w-[48%] lg:w-full h-full">
              <div className="flex items-center w-full lg:w-[50%] lg:border-r-2 lg:border-dotted">
                <div className="w-full lg:w-[70%]">
                  <p className="text-[14px] lg:text-[21px] text-[#000000] font-[500] text-center lg:text-left w-full lg:pl-10">
                    Inspect and Style: Our experts will inspect the property for
                    any modifications or maintenance.
                  </p>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="absolute w-8 h-8 rounded-full bg-[#612ACE] left-[50%] right-[50%] top-[50%] ml-[-18px] mt-[-20px]"></div>
              </div>
              <div className="flex justify-center items-center w-full lg:w-[50%] lg:justify-end">
                <div className="w-full lg:w-[70%] mb-4 lg:mb-0">
                  <img src={require("images/listProperty/sign2.png")} alt="" loading="lazy"/>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="flex flex-col lg:flex-row relative w-[48%] lg:w-full h-full">
              <div className="flex justify-center items-center w-full lg:w-[50%] lg:justify-start lg:border-r-2 lg:border-dotted">
                <div className="w-full lg:w-[70%] mb-4 lg:mb-0 lg:pt-16">
                  <img src={require("images/listProperty/consult.png")} alt="" loading="lazy" />
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="absolute w-8 h-8 rounded-full bg-[#612ACE] left-[50%] right-[50%] top-[50%] ml-[-18px] mt-[-20px]"></div>
              </div>
              <div className="flex items-center w-full lg:w-[50%] lg:justify-end">
                <div className="w-full lg:w-[70%]">
                  <p className="text-[14px] lg:text-[21px] text-[#000000] font-[500] text-center lg:text-left w-full lg:pl-10">
                    List & Capture: We take professional photograph and list
                    your property across platforms
                  </p>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className="flex flex-col-reverse lg:flex-row relative w-[48%] lg:w-full h-full mt-8 lg:mt-0">
              <div className="flex items-center w-full lg:w-[50%] ">
                <div className="w-full lg:w-[70%]">
                  <p className="text-[14px] lg:text-[21px] text-[#000000] font-[500] text-center lg:text-left w-full lg:pl-10">
                    Enjoy the earnings: Sit back and enjoy watching your wallet
                    grow.
                  </p>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="absolute w-8 h-8 rounded-full bg-[#612ACE] left-[50%] right-[50%] z-20 top-[50%] ml-[-18px] mt-[-20px]"></div>
                <div className="absolute w-[2px] h-[50%] left-[50%] right-[50%] lg:border-dotted  lg:border ml-[-2px] "></div>
              </div>
              <div className="flex justify-center items-center w-full lg:w-[50%] lg:justify-end">
                <div className="w-full lg:w-[70%] mb-4 lg:mb-0">
                  <img
                    src={require("images/listProperty/div4.png")}
                    className="h-full w-full"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCategory3;
