const Reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    case "SET_ALL_ROOM_LISTINGS":
      return {
        ...state,
        roomListings: action.payload,
      };
    default:
      return state;
  }
};
export default Reducer;
