import React, { useState } from "react";
import { FC } from "react";
import AnimateHeight from "react-animate-height";
import Img from "images/place/dubai-ma.jpg";
import { Link } from "react-router-dom";
const Comment = (props: any) => {
  const { className, faqPage } = props;
  const [height, setHeight]: any = useState(0);
  const [selectedFaq, setSelectedFaq]: any = useState("general");
  const allFAQs: any = {
    // general
    general: [
      {
        id: 1,
        name: "Are there any requirements at the time of check-in?",
        answere:
          "At the time of check-in, we will need your Passport (International clients) or a valid National ID card. You would also be required to sign a guest registration form. There may also be a requirement for a security deposit.",
      },
      {
        id: 2,
        name: "Where can I confirm that I have paid for my booking?",
        answere:
          "Upon booking, you will receive an email confirmation with the booking details.",
      },
      {
        id: 3,
        name: "What is the standard timing for check-in and checkout?",
        answere:
          "Check in: 15:00 hours. <br/> <p class='mt-2'>Check out: 12:00 hours.</p>",
      },
      {
        id: 4,
        name: "Can I request additional services like early check-in, late check-out, extra bed, transportation, or anything else?",
        answere:
          "If you’d like to request an extra service, please contact us on +971 50 1510743, 2 days before arrival. We don’t guarantee, but we may be able to help.",
      },
      {
        id: 5,
        name: "Why do I need to give a security deposit?",
        answere:
          "You may be required to pay a security deposit upon arrival, this is intended to cover any damage to the property, loss of items, or any additional expenses incurred by the property owner during your stay. The security deposit, less of any deductions as stated earlier, will be refunded to you.",
      },
      {
        id: 6,
        name: "How to cancel/modify my booking?",
        answere:
          "To initiate your cancellation/modification process, kindly contact our customer support team via Whatsapp.",
      },
      {
        id: 7,
        name: "Where can I find the cancellation policy?",
        answere: "You can find the cancelation policy on the bottom right corner of the website or you can click ",
      },
    ],
    // property owner
    propertyOwner: [
      {
        id: 1,
        name: "How much can I make from my property?",
        answere:
          "Number of bedrooms, the demand and the occupancy rate are the major factors to be taken into consideration while generating a revenue forecast. Our team of experienced professionals will provide you with the most accurate revenue estimate for your property.",
      },
      {
        id: 2,
        name: "Can I list the property on my own on Duomo website?",
        answere:
          "This option is currently unavailable. Contact us on +971 50 1510743, and we will assist you in making the necessary arrangements. We will help you list the property on DUOMO as well as other platforms.",
      },
      {
        id: 3,
        name: " If my apartment is unfurnished, can I still list it with you?",
        answere:
          "At Duomo, we take care of the furnishing and styling your property. Contact us on +971 50 1510743 for further information.",
      },
      {
        id: 4,
        name: "What happens if something is damaged during a guest’s stay?",
        answere:
          "Our team ensures complete screening of the guests and property inspection before and after each stay. In the event of any damage, we will handle all aspects of maintenance. We also have a guest deposit policy to mitigate any such damage related situations.",
      },
    ],
    // corporate Partner
    corporatePartner: [
      {
        id: 1,
        name: "What is Duomo's Commercial Affiliates Program ?",
        answere:
          "Elevate your business accommodations with our specialized Corporate Partners Program. Tailored for businesses and organizations, this exclusive offering provides high-quality and cost-effective accommodations for your employees or clients. Discover a new level of comfort and convenience for your corporate stays.",
      },
      {
        id: 2,
        name: "What Benefits Await You in Unlocking Duomo's Corporate Affiliate Program?",
        answere:
          "As a corporate partner, you enjoy exclusive access to a diverse portfolio of meticulously managed properties. Tailor accommodations to your specific needs, offering unmatched comfort, cost-efficiency, and flexibility for your valued employees or clients",
      },
      {
        id: 3,
        name: "Ensuring Safety: What Measures Are in Place for Corporate Guests?",
        answere:
          "At Duomo, your safety is our priority. Our commitment to stringent safety and security standards includes thorough guest screening and clear guidelines for property behavior. Rest assured, these measures are in place to ensure the well-being of all our valued corporate guests",
      },
      {
        id: 4,
        name: "Wondering How to Start as a Corporate Partner with Duomo?",
        answere:
          "Getting started is easy – simply reach out to our dedicated Corporate Partners team. Share your specific accommodation needs and preferences, and we'll collaborate with you to tailor a partnership that perfectly suits the requirements of your organization.",
      },
    ],
  };
  return (
    <div className={`nc-FAQ ${className}`}>
      {/*  */}
      <div className="w-full pb-9">
        <div className="flex flex-wrap justify-center px-5 lg:px-0">
          <button
            className={`border rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] font-[500] ${
              selectedFaq === "general"
                ? "border-[#353945] bg-[#353945] text-white "
                : "text-[#777E90] border-white"
            }`}
            onClick={() => setSelectedFaq("general")}
          >
            General
          </button>
          <button
            className={`border rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] font-[500] ${
              selectedFaq === "propertyOwner"
                ? "border-[#353945] bg-[#353945] text-white "
                : "text-[#777E90] border-white"
            }`}
            onClick={() => setSelectedFaq("propertyOwner")}
          >
            Property Owner
          </button>
          <button
            className={`border-white border hover:border-[#353945] hover:bg-[#353945] hover:text-white  rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3 ${
              selectedFaq === "corporatePartner"
                ? "border-[#353945] bg-[#353945] text-white "
                : "text-[#777E90] border-white"
            }`}
            onClick={() => setSelectedFaq("corporatePartner")}
          >
            Corporate Partner
          </button>
          {/* <button className="border-white border hover:border-[#353945] hover:bg-[#353945] hover:text-white text-[#777E90] rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3">
            Booking
          </button> */}
          {/* <button className="border-white border hover:border-[#353945] hover:bg-[#353945] hover:text-white text-[#777E90] rounded-full py-2 px-5 mt-6 cursor-pointer mr-3 text-[16px] mr-3">
            Support
          </button> */}
        </div>
      </div>
      {/* collapsible */}
      <div className="pt-16">
        {allFAQs[selectedFaq].map((item: any) => (
          <div className="border-t" key={item.id}>
            <div
              className="flex justify-between items-center py-6 cursor-pointer"
              aria-expanded={height !== 0}
              aria-controls="example-panel"
              onClick={() =>
                item.id === height ? setHeight(0) : setHeight(item.id)
              }
            >
              <h1 className="text-[#23262F] text-[500] text-left text-[16px] font-[500]">
                {item.name}
              </h1>
              <button
                className={` left-button bg-white dark:bg-neutral-900 dark:hover:border-neutral-500 text-[#777E90] rounded-full flex items-center justify-center hover:border-neutral-300 transition ease-in-out delay-150 duration-300
                ${item.id === height ? "rotate-[90deg] " : "rotate-[-90deg] "}
                `}
                title="Prev"
                data-glide-dir="<"
              >
                <i className="las la-angle-left"></i>
              </button>
            </div>
            <AnimateHeight
              id="example-panel"
              duration={500}
              height={item.id === height ? "auto" : 0} // see props documentation below
            >
              {" "}
              <div className="flex flex-col lg:flex-row justify-between pb-6">
                <div className="w-full">
                  <div className="">
                    {selectedFaq === "general" && item.id === 7 ? (
                      <p className="text-[16px] text-left text-[#777E90] font-[500] py-1">
                        {item.answere}{" "}
                        <Link
                          className="text-[#612ACE] cursor-pointer"
                          to="/cancellation-policy"
                        >
                          here
                        </Link>
                      </p>
                    ) : (
                      <p
                        className="text-[16px] text-left text-[#777E90] font-[500] py-1"
                        dangerouslySetInnerHTML={{ __html: item.answere }}
                      ></p>
                    )}
                  </div>
                </div>
              </div>
            </AnimateHeight>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Comment;
