import Banner from "components/Homepage/Banner";
// import SectionSliderNewCategories from "components/Homepage/SlideCategory";
// import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
// import Experiences from "components/Homepage/Experiences";
// import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import Vacations from "components/Homepage/Vacations";
// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHowItWorks from "components/Homepage/SectionHowItWork";
// import FAQ from "shared/Faq/Faq";
import { TaxonomyType } from "data/types";
import { Helmet } from "react-helmet";
// import SectionVideos from "./SectionVideos";
// import { useContext, useEffect } from "react";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Dubai Marina",
    taxonomy: "category",
    count: 188288,
    thumbnail: require("../../images/place/dubai-ma.jpg"),
  },
  {
    id: "2",
    href: "#",
    name: "Jumeirah Lakes Towers (JLT)",
    taxonomy: "category",
    count: 188288,
    thumbnail: require("../../images/place/jlt.jpg"),
  },
  {
    id: "2",
    href: "#",
    name: "Jumeirah Beach Residence (JBR)",
    taxonomy: "category",
    count: 188288,
    thumbnail: require("../../images/place/jb.jpg"),
  },
  {
    id: "2",
    href: "#",
    name: "Barsha",
    taxonomy: "category",
    count: 188288,
    thumbnail: require("../../images/place/al-barsha.png"),
  },
  {
    id: "2",
    href: "#",
    name: "Downtown Dubai",
    taxonomy: "category",
    count: 188288,
    thumbnail: require("../../images/place/down.jpg"),
  },
  {
    id: "2",
    href: "#",
    name: "Dubai Hills",
    taxonomy: "category",
    count: 188288,
    thumbnail: require("../../images/place/dubai-hills.png"),
  },
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Duomo Tourism</title>
      </Helmet>
      {/* Hero Section */}
      <div className="">
        <div className="relative mx-[3%] lg:mx-[3%] xl:mx-[3%] 2xl:mx-[3%]">
          <Banner />
        </div>
        <div className="mx-[3%] lg:mx-[5%] xl:mx-[5%] 2xl:mx-[5%] relative space-y-24 mb-24 lg:space-y-20 lg:mb-32">
          {/* <div className="relative pt-10 pb-4 lg:px-10">
            <SectionGridFeaturePlaces />
          </div> */}
          {/* <div>
            <Experiences />
          </div> */}
          <div className="lg:mx-[7%] 2xl:mx-[15%] my-10">
            <SectionHowItWorks />
          </div>
          <div className="lg:mx-[7%] 2xl:mx-[15%] my-10">
            <Vacations />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
