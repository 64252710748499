import React from "react";

const ServicesDetails = () => {
  const ServicesData = [
    {
      id: 1,
      Title: "Listing and Photography",
      paragraph:
        "We craft a compelling and detailed property listing, complemented by high-quality photographs. Duomo aims to showcase the property’s unique features, amenities, and overall appeal, capturing the attention of potential guests.",
      btn: "Enquire Now",
    },
    {
      id: 2,
      Title: "Price Adjustments",
      paragraph:
        "Duomo leverages cutting-edge technology and extensive local knowledge to monitor market trends and utilize algorithms, ensuring a steady flow of guests for our hosts.",
      btn: "Enquire Now",
    },
    {
      id: 3,
      Title: "Guest correspondence",
      paragraph:
        "Ensuring the happiness of our guests is our top priority. We prioritize fast and friendly communication with guests, ensuring a seamless and enjoyable trip from start to finish.",
      btn: "Enquire Now",
    },
    {
      id: 4,
      Title: "Maintenance",
      paragraph:
        "By offering full maintenance services, Duomo will be providing property owners with peace of mind, ensuring that their investment is well-cared for and consistently meets or exceeds guest expectations.",
      btn: "Enquire Now",
    },
    {
      id: 5,
      Title: "Cleaning & sanitation services",
      paragraph:
        "Duomo promptly dispatches a skilled team to thoroughly clean the property after each guest's departure, replacing linens and amenities. This systematic attention ensures a sparkling, 5-star-ready home for the next guest's delightful experience.",
      btn: "Enquire Now",
    },
    {
      id: 6,
      Title: "Furnish & Style",
      paragraph:
        "Our specialized team handles home furnishings, and styling down to the last teaspoon, providing competitive rates for ideal rental returns.        ",
      btn: "Enquire Now",
    },
    {
      id: 7,
      Title: "Legal Measures",
      paragraph:
        "The UAE government granted legal approval for the use of Duomo In 2023. We can take care of registering your property with the Department of Tourism and Commerce Marketing (DTCM) on your behalf. Our team will discuss applicable fees directly with you.",
      btn: "Enquire Now",
    },
    {
      id: 8,
      Title: "Concierge services",
      paragraph:
        "By offering concierge services, Duomo aims to elevate the overall guest experience, providing a level of service and attention to detail that goes beyond traditional accommodations.",
      btn: "Enquire Now",
    },
    {
      id: 9,
      Title: "Toiletries & Extra",
      paragraph:
        "Toiletries and additional amenities items will be provided beyond the essential necessities. It includes a range of items such as shampoo, conditioner, soap, toothpaste, and other personal care products. Additionally, ''extra'' items may encompass additional comforts or conveniences that enhance the overall guest experience, such as snacks, beverages, and etc.",
      btn: "Enquire Now",
    },
    // {
    //   id: 5,
    //   Title: "Guest Screening",
    //   paragraph:
    //     "When allowing a guest into your home security is a must. Strict vetting of all guests is conducted to guarantee peace of mind. Guests are also met upon check out to ensure the property is in fine order with no damage or breakages.",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 5,
    //   Title: "24Hr Check-In ",
    //   paragraph:
    //     "We will always ensure access is provided 24 hrs a day – 7 days a week. Guests are guided through the home with an inventory list to be signed by them upon keys being handed over. ",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 6,
    //   Title: "Toiletries & Extras",
    //   paragraph:
    //     "Duomo can provide all the creature comforts to your guests, from full toiletries to the ‘must haves’ in the fridge. With this 5* service your guest can relax knowing that upon check in they do not have to worry about finding the nearest shop.",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 7,
    //   Title: "Home Cleaning",
    //   paragraph:
    //     "After the departure of each guest, Duomo will send in a team of professionals to clean and replace all linen, towels and additional condiments. The home is then left sparkling for the next guests arrival ensuring a 5* rating.",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 8,
    //   Title: "Full Maintenance",
    //   paragraph:
    //     "There is always a chance that something can malfunction or simply break in any home. We have a team on stand by to attend to any issue to ensure the problem is rectified and the guest can continue their stay in peace.",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 9,
    //   Title: "Above & Beyond",
    //   paragraph:
    //     "From arranging basic tasks such as key cutting, mid-range maintenance like painting, to the professional jobs such as pest control and plumbing, we are here to help. An inventory service can also be provided for that extra peace of mind.",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 10,
    //   Title: "Home Furnishing",
    //   paragraph:
    //     "No problem, we have a team that specialises in the furnishing of homes right down to the last tea spoon. We offer competitive rates to give you maximum rental returns.",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 11,
    //   Title: "Government Legislation",
    //   paragraph:
    //     "In 2023 the Dubai government legalised the use of Duomo. Duomo can register your property on your behalf with the Department of Tourism and Commerce Marketing (DTCM). Fees are applicable and will be discussed with you by a member of our team.        ",
    //   btn: "Enquire Now",
    // },
    // {
    //   id: 12,
    //   Title: "Concierge Services",
    //   paragraph:
    //     "From arranging basic tasks such as key cutting, mid-range maintenance like painting, to the professional jobs such as pest control and plumbing, we are here to help. An inventory service can also be provided for that extra peace of mind. Nanny, Rent a car, Chef, Airport pick up & drop off",
    //   btn: "Enquire Now",
    // },
  ];
  return (
    <div className="mx-[3%] lg:mx-[5%] xl:mx-[8%] 2xl:mx-[13%] md:mt-[-95px] mb-24">
      <div className=" grid gap-6 md:gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
        {ServicesData.map((item, index) => (
          <div key={item?.id} className="w-full">
            <div className="border border-[#E1E1E1] rounded-[10px] py-6 px-6 2xl:px-4  h-full">
              <div className="flex items-center ">
                <img
                  src={require(`images/vectors/Vector${index + 1}.png`)}
                  className="h-5"
                  alt=""
                />

                <h2 className="text-[20px]  ps-2 pe-2 font-[600]">
                  {item?.Title}
                </h2>
              </div>
              <div className="h-[1px] bg-[#E1E1E1] my-3"></div>
              <div className="">
                <p className="text-[14px] text-[#737373] lg:leading-[25px]">
                  {item?.paragraph}
                </p>
              </div>
              {/* <button className="border border-[#E1E1E1] hover:bg-[#E1E1E1] hover:border-[#E1E1E1] flex items-center pt-2 pb-2 w-fit p-3 mt-4 rounded-[8px]">
                <img
                  src={require("images/messageIcon.png")}
                  className="h-6"
                  alt=""
                />
                <span className="text-[14px] pl-4 font-[600]">{item?.btn}</span>
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesDetails;
