export const allListings = (data: any) => {
  let lists: any = [];
  console.log("data", data);
  data?.data?.listings?.map((item: any) => {
    lists?.push({
      id: item.id,
      authorId: 10,
      date: "May 20, 2021",
      href: "/listings",
      listingCategoryId: 17,
      title: item.name,
      featuredImage: item.thumbnail_file,
      galleryImgs: [
        item.thumbnail_file,
        // "https://images.pexels.com/photos/1179156/pexels-photo-1179156.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        // "https://images.pexels.com/photos/2506988/pexels-photo-2506988.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        // "https://images.pexels.com/photos/2373201/pexels-photo-2373201.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
      ],
      commentCount: 0,
      viewCount: 0,
      like: false,
      address: item.nickname,
      reviewStart: 4.8,
      reviewCount: 28,
      price: item.currency + " " + item.price,
      maxGuests: 6,
      bedrooms: item.beds,
      bathrooms: 3,
      saleOff: "",
      isAds: false,
      map: {
        lat: item.lat,
        lng: item.lng,
      },
      author: {
        id: 10,
        firstName: "Mimi",
        lastName: "Fones",
        displayName: "Fones Mimi",
        email: "mfones9@canalblog.com",
        gender: "Agender",
        avatar: "/static/media/Image-10.ed67665686700f4b60ec.png",
        count: 111,
        href: "/author",
        desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
        jobName: "Author Job",
        bgImage: "x",
      },
      listingCategory: {
        id: 17,
        name: item.property_type,
        href: "archive-stay/the-demo-archive-slug",
        thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
        count: 2855,
        taxonomy: "category",
        listingType: "stay",
      },
    });
  });
  return lists;
};
