import React, { FC, useContext, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCard from "components/StayCard/StayCard";
import GoogleMapReact from "google-map-react";
// import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import StayCardPlaceholder from "shared/StayCard/StayCard";
import { allListings } from "utils/listings";
import { GlobalContext } from "context/globalContext/globalContext";

// const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const { state } = useContext(GlobalContext);
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);

  // const DEMO_STAYS = [
  //   {
  //     id: "5c9fd5fb-1624-4fb6-be5c-027c920292d5",
  //     authorId: 10,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 17,
  //     title: "TRYP by Wyndham Dubai ",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/1268871/pexels-photo-1268871.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1179156/pexels-photo-1179156.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2506988/pexels-photo-2506988.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2373201/pexels-photo-2373201.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 70,
  //     viewCount: 602,
  //     like: false,
  //     address: "1 Anzinger Court",
  //     reviewStart: 4.8,
  //     reviewCount: 28,
  //     price: "$26",
  //     maxGuests: 6,
  //     bedrooms: 10,
  //     bathrooms: 3,
  //     saleOff: "-20% today",
  //     isAds: true,
  //     map: {
  //       lat: 25.073070763979,
  //       lng: 55.128438920593,
  //       // lat: 55.2094559,
  //       // lng: 61.5594641,
  //     },
  //     author: {
  //       id: 10,
  //       firstName: "Mimi",
  //       lastName: "Fones",
  //       displayName: "Fones Mimi",
  //       email: "mfones9@canalblog.com",
  //       gender: "Agender",
  //       avatar: "/static/media/Image-10.ed67665686700f4b60ec.png",
  //       count: 111,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 17,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
  //       count: 2855,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "9eb9eb92-27a6-4703-b67f-00f92663267d",
  //     authorId: 1,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 9,
  //     title: "The sunset apartment by Arabian nights ",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/6434634/pexels-photo-6434634.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/3201735/pexels-photo-3201735.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/4577673/pexels-photo-4577673.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/6474535/pexels-photo-6474535.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 16,
  //     viewCount: 196,
  //     like: false,
  //     address: "32923 Judy Hill",
  //     reviewStart: 4.4,
  //     reviewCount: 198,
  //     price: "$250",
  //     maxGuests: 10,
  //     bedrooms: 6,
  //     bathrooms: 7,
  //     saleOff: "-10% today",
  //     isAds: null,
  //     map: {
  //       lat: 55.1972153,
  //       lng: 61.4407266,
  //     },
  //     author: {
  //       id: 1,
  //       firstName: "Alric",
  //       lastName: "Truelock",
  //       displayName: "Truelock Alric",
  //       email: "atruelock0@skype.com",
  //       gender: "Bigender",
  //       avatar: "/static/media/Image-1.ed67665686700f4b60ec.png",
  //       bgImage: "x",
  //       count: 40,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //     },
  //     listingCategory: {
  //       id: 9,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
  //       count: 1465,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "92f8c68a-a2da-466a-ae0c-fae6c19247e7",
  //     authorId: 6,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 11,
  //     title: "Frank Porter goldcrest executive",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/4186560/pexels-photo-4186560.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2404422/pexels-photo-2404422.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/756078/pexels-photo-756078.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 47,
  //     viewCount: 843,
  //     like: true,
  //     address: "6731 Killdeer Park",
  //     reviewStart: 3.6,
  //     reviewCount: 16,
  //     price: "$278",
  //     maxGuests: 9,
  //     bedrooms: 9,
  //     bathrooms: 8,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 55.247483,
  //       lng: 61.5229791,
  //     },
  //     author: {
  //       id: 6,
  //       firstName: "Donna",
  //       lastName: "Friar",
  //       displayName: "Friar Donna",
  //       email: "dfriar5@telegraph.co.uk",
  //       gender: "Agender",
  //       avatar: "/static/media/Image-6.ed67665686700f4b60ec.png",
  //       count: 31,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 11,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
  //       count: 2775,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "98eb8843-0ae1-4b66-a410-a82bfcc5265c",
  //     authorId: 8,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 6,
  //     title: "Voco Bonnington Dubai",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/2290738/pexels-photo-2290738.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/751268/pexels-photo-751268.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/3201922/pexels-photo-3201922.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2404422/pexels-photo-2404422.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 6,
  //     viewCount: 91,
  //     like: false,
  //     address: "35 Sherman Park",
  //     reviewStart: 4.8,
  //     reviewCount: 34,
  //     price: "$40",
  //     maxGuests: 6,
  //     bedrooms: 7,
  //     bathrooms: 5,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 55.2342034,
  //       lng: 61.5409607,
  //     },
  //     author: {
  //       id: 8,
  //       firstName: "Claudetta",
  //       lastName: "Sleite",
  //       displayName: "Sleite Claudetta",
  //       email: "csleite7@godaddy.com",
  //       gender: "Genderqueer",
  //       avatar: "/static/media/Image-8.ed67665686700f4b60ec.png",
  //       count: 35,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 6,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
  //       count: 1614,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "909c8c14-2f75-4d65-993b-66b8e03e1182",
  //     authorId: 2,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 11,
  //     title: "Grand Excelsior hotel Bur Bubai",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/261327/pexels-photo-261327.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2079452/pexels-photo-2079452.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/261410/pexels-photo-261410.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2983472/pexels-photo-2983472.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 91,
  //     viewCount: 574,
  //     like: false,
  //     address: "3 Crest Line Park",
  //     reviewStart: 3.4,
  //     reviewCount: 340,
  //     price: "$147",
  //     maxGuests: 8,
  //     bedrooms: 3,
  //     bathrooms: 2,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 55.2288039,
  //       lng: 61.6050232,
  //     },
  //     author: {
  //       id: 2,
  //       firstName: "Chariot",
  //       lastName: "Birrell",
  //       displayName: "Birrell Chariot",
  //       email: "cbirrell1@google.com.hk",
  //       gender: "Genderfluid",
  //       avatar: "/static/media/Image-2.ed67665686700f4b60ec.png",
  //       count: 113,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 11,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
  //       count: 2775,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "0ef1d10a-2faf-408f-a874-9cdb18044b36",
  //     authorId: 4,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 7,
  //     title: "DAMAC hills 2 hotel, an edge by Rotana hotel",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/2373201/pexels-photo-2373201.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2736187/pexels-photo-2736187.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/6900098/pexels-photo-6900098.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1371313/pexels-photo-1371313.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 21,
  //     viewCount: 210,
  //     like: true,
  //     address: "55974 Waxwing Junction",
  //     reviewStart: 3.8,
  //     reviewCount: 508,
  //     price: "$90",
  //     maxGuests: 8,
  //     bedrooms: 7,
  //     bathrooms: 7,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 55.1952216,
  //       lng: 61.6115793,
  //     },
  //     author: {
  //       id: 4,
  //       firstName: "Agnes",
  //       lastName: "Falconar",
  //       displayName: "Falconar Agnes",
  //       email: "afalconar3@google.ru",
  //       gender: "Non-binary",
  //       avatar: "/static/media/Image-4.ed67665686700f4b60ec.png",
  //       count: 36,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 7,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/cc0000/ffffff",
  //       count: 1577,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "b0f4f850-4813-4be5-8131-716ca1b434a0",
  //     authorId: 4,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 20,
  //     title: "Swissotel Al Murooj Dubai",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/3068519/pexels-photo-3068519.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1638341/pexels-photo-1638341.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/8506437/pexels-photo-8506437.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/3935311/pexels-photo-3935311.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 10,
  //     viewCount: 311,
  //     like: false,
  //     address: "79361 Chinook Place",
  //     reviewStart: 3,
  //     reviewCount: 481,
  //     price: "$282",
  //     maxGuests: 9,
  //     bedrooms: 2,
  //     bathrooms: 5,
  //     saleOff: "-10% today",
  //     isAds: null,
  //     map: {
  //       lat: 55.2211607,
  //       lng: 61.5065318,
  //     },
  //     author: {
  //       id: 4,
  //       firstName: "Agnes",
  //       lastName: "Falconar",
  //       displayName: "Falconar Agnes",
  //       email: "afalconar3@google.ru",
  //       gender: "Non-binary",
  //       avatar: "/static/media/Image-4.ed67665686700f4b60ec.png",
  //       count: 36,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 20,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
  //       count: 2279,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "3f2c4066-3e8d-43d0-a6b0-73f94ef2cf43",
  //     authorId: 8,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 10,
  //     title: "Howard Johnson Plaza by Wyndham Dubai Deira",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/2343466/pexels-photo-2343466.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  //       "https://images.pexels.com/photos/276746/pexels-photo-276746.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1571469/pexels-photo-1571469.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/3946663/pexels-photo-3946663.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 40,
  //     viewCount: 905,
  //     like: true,
  //     address: "6 Chive Avenue",
  //     reviewStart: 3.9,
  //     reviewCount: 188,
  //     price: "$79",
  //     maxGuests: 6,
  //     bedrooms: 7,
  //     bathrooms: 4,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 55.2126851,
  //       lng: 61.4912261,
  //     },
  //     author: {
  //       id: 8,
  //       firstName: "Claudetta",
  //       lastName: "Sleite",
  //       displayName: "Sleite Claudetta",
  //       email: "csleite7@godaddy.com",
  //       gender: "Genderqueer",
  //       avatar: "/static/media/Image-8.ed67665686700f4b60ec.png",
  //       count: 35,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 10,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
  //       count: 1945,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "3ba9e6bc-4758-4ce1-95f9-5bf23e7ef9dd",
  //     authorId: 1,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 8,
  //     title: "OYO Sunrise Hotel ",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/1571469/pexels-photo-1571469.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1571468/pexels-photo-1571468.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1909752/pexels-photo-1909752.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/418806/pexels-photo-418806.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 38,
  //     viewCount: 588,
  //     like: true,
  //     address: "77058 Havey Terrace",
  //     reviewStart: 3.4,
  //     reviewCount: 450,
  //     price: "$65",
  //     maxGuests: 8,
  //     bedrooms: 7,
  //     bathrooms: 6,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 55.185907,
  //       lng: 61.4864299,
  //     },
  //     author: {
  //       id: 1,
  //       firstName: "Alric",
  //       lastName: "Truelock",
  //       displayName: "Truelock Alric",
  //       email: "atruelock0@skype.com",
  //       gender: "Bigender",
  //       avatar: "/static/media/Image-1.ed67665686700f4b60ec.png",
  //       bgImage: "x",
  //       count: 40,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //     },
  //     listingCategory: {
  //       id: 8,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
  //       count: 1721,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "7be2ce29-abd6-4836-8fff-a156a6a8e797",
  //     authorId: 2,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 1,
  //     title: "The Bridge House; BW Signature Collection ",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/3935315/pexels-photo-3935315.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1571453/pexels-photo-1571453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2133230/pexels-photo-2133230.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/6480707/pexels-photo-6480707.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 49,
  //     viewCount: 794,
  //     like: true,
  //     address: "691 Northridge Plaza",
  //     reviewStart: 4.5,
  //     reviewCount: 363,
  //     price: "$265",
  //     maxGuests: 8,
  //     bedrooms: 2,
  //     bathrooms: 10,
  //     saleOff: "-10% today",
  //     isAds: null,
  //     map: {
  //       lat: 55.1735735,
  //       lng: 61.4991107,
  //     },
  //     author: {
  //       id: 2,
  //       firstName: "Chariot",
  //       lastName: "Birrell",
  //       displayName: "Birrell Chariot",
  //       email: "cbirrell1@google.com.hk",
  //       gender: "Genderfluid",
  //       avatar: "/static/media/Image-2.ed67665686700f4b60ec.png",
  //       count: 113,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 1,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
  //       count: 2669,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "23fa165a-9857-4c21-a91a-e4c50bf5c60a",
  //     authorId: 3,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 19,
  //     title: "Hartford Hall on School Lane ",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/1428348/pexels-photo-1428348.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/6480707/pexels-photo-6480707.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/2507010/pexels-photo-2507010.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/974382/pexels-photo-974382.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 13,
  //     viewCount: 566,
  //     like: true,
  //     address: "28913 Ridgeway Terrace",
  //     reviewStart: 3.9,
  //     reviewCount: 436,
  //     price: "$122",
  //     maxGuests: 5,
  //     bedrooms: 3,
  //     bathrooms: 4,
  //     saleOff: "-10% today",
  //     isAds: null,
  //     map: {
  //       lat: 59.955413,
  //       lng: 30.337844,
  //     },
  //     author: {
  //       id: 3,
  //       firstName: "Nathanil",
  //       lastName: "Foulcher",
  //       displayName: "Foulcher Nathanil",
  //       email: "nfoulcher2@google.com.br",
  //       gender: "Bigender",
  //       avatar: "/static/media/Image-3.ed67665686700f4b60ec.png",
  //       count: 43,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 19,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/5fa2dd/ffffff",
  //       count: 1392,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  //   {
  //     id: "19522076-7a9f-4dcf-a97b-fc72da550a3f",
  //     authorId: 7,
  //     date: "May 20, 2021",
  //     href: "/listing-stay-detail",
  //     listingCategoryId: 4,
  //     title: "Red Lion, Wigan by Marston's Inns ",
  //     featuredImage:
  //       "https://images.pexels.com/photos/5191371/pexels-photo-5191371.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     galleryImgs: [
  //       "https://images.pexels.com/photos/1643383/pexels-photo-1643383.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1080719/pexels-photo-1080719.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/1080719/pexels-photo-1080719.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //       "https://images.pexels.com/photos/279867/pexels-photo-279867.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  //     ],
  //     commentCount: 17,
  //     viewCount: 12,
  //     like: true,
  //     address: "43 Columbus Drive",
  //     reviewStart: 3.4,
  //     reviewCount: 301,
  //     price: "$370",
  //     maxGuests: 5,
  //     bedrooms: 7,
  //     bathrooms: 2,
  //     saleOff: null,
  //     isAds: null,
  //     map: {
  //       lat: 59.955413,
  //       lng: 30.337844,
  //     },
  //     author: {
  //       id: 7,
  //       firstName: "Sergei",
  //       lastName: "Royal",
  //       displayName: "Royal Sergei",
  //       email: "sroyal6@netlog.com",
  //       gender: "Non-binary",
  //       avatar: "/static/media/Image-7.ed67665686700f4b60ec.png",
  //       count: 102,
  //       href: "/author",
  //       desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
  //       jobName: "Author Job",
  //       bgImage: "x",
  //     },
  //     listingCategory: {
  //       id: 4,
  //       name: "Entire cabin",
  //       href: "archive-stay/the-demo-archive-slug",
  //       thumbnail: "http://dummyimage.com/300x300.png/dddddd/000000",
  //       count: 2287,
  //       taxonomy: "category",
  //       listingType: "stay",
  //     },
  //   },
  // ];

  const mapDummyData = [
    {
      id: 1,
      authorId: 0,
      date: "",
      href: "",
      listingCategoryId: "",
      title: "",
      featuredImage: "",
      galleryImgs: "",
      map: {
        lat: "",
        lng: "",
      },
    },
  ];

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <div className={`mb-6 mt-4`}>
            {/* <h2 className="text-4xl font-semibold">{heading}</h2> */}
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3 text-right">
              300+ homes available
            </span>
          </div>
          {/* <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div> */}
          {/* <div className="grid grid-cols-1 gap-8">
            {DEMO_STAYS.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <StayCardH data={item} />
              </div>
            ))}
          </div> */}
          <div className="grid grid-cols-1 gap-6 md:gap-3 lg:gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2">
            {state?.loader
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(() => (
                  <StayCardPlaceholder />
                ))
              : allListings(state?.roomListings)?.map((stay: any) => (
                  <div
                    key={stay.id}
                    onMouseEnter={() => setCurrentHoverID((_) => stay.id)}
                    onMouseLeave={() => setCurrentHoverID((_) => -1)}
                  >
                    <StayCard key={stay.id} data={stay} />
                  </div>
                ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div>
            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
              }}
              defaultZoom={12}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={
                state?.loader
                  ? allListings(state?.roomListings)
                  : allListings(state?.roomListings)[0].map
              }
            >
              {state?.loader
                ? allListings(state?.roomListings).map((item: any) => (
                    <AnyReactComponent
                      // isSelected={currentHoverID === item.id}
                      key={item.id}
                      lat={item.map.lat}
                      lng={item.map.lng}
                      listing={item}
                    />
                  ))
                : allListings(state?.roomListings).map((item: any) => (
                    <AnyReactComponent
                      isSelected={currentHoverID === item.id}
                      key={item.id}
                      lat={item.map.lat}
                      lng={item.map.lng}
                      listing={item}
                    />
                  ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
