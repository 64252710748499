import axios from "axios";
const header = {
  headers: {
    "x-api-key": "OEe3I38PggJmAI44JUUxcIuIJRjWYHA6", //the token is a variable which holds the token
  },
};
// GET API CAll
export const GetAPIService = (Url: any) => {
  return axios.get(process.env.REACT_APP_FRONTED_URL + Url, header);
};
// Post API CAll
export const PostAPIService = (Url: any, payload: any, config?: any) => {
  return axios.post(process.env.REACT_APP_FRONTED_URL + Url, payload, config);
};
// Put API Call
export const PutAPIService = (Url: any, payload: any) => {
  return axios.put(process.env.REACT_APP_FRONTED_URL + Url, payload);
};
// Delete API CAll
export const DeleteAPIService = (Url: any, payload: any) => {
  return axios.delete(process.env.REACT_APP_FRONTED_URL + Url, payload);
};
