import React, { FC } from "react";
// import OurServicesMain from "../../components/Services/OurServices";
// import ServicesDetails from "../../components/Services/ServicesDetails";
// import PropertRefrelSection from "components/PropertyOwner/PropertyReferlFreind";
import FAQ from "shared/Faq/Faq";
import { withRouter } from "react-router";

const OurServics = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id="PageAbout"
    >
      <div className="mx-[3%] lg:mx-[12%] 2xl:mx-[10%] py-14">
        <FAQ />
      </div>
    </div>
  );
};

export default withRouter(OurServics);
