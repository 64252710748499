import React, { FC, ReactNode, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";

const SectionHero = () => {
  return (
    <div className={`nc-SectionHero relative`} data-nc-id="SectionHero">
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full">
          <h2 className="text-3xl font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 pt-2">
            Cancellation Terms
          </h2>{" "}
        </div>
      </div>
      <div className="pt-10">
        <div>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Your cancellation policy can always be found in your booking
            confirmation email.
          </span>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            To understand your refund policy, initiate the cancellation process
            and we will give a detailed breakdown. Depending on the duration of
            your stay, the timing of your cancellation, and the applicable
            cancellation policies for your reservation.
          </span>
        </div>
        <div>
          <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
            1. Cancellation Deadlines
          </h3>
          <div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Cancellation deadlines for receiving refunds are measured from the
              check-in time for the booking in its local time zone, or 3:00 PM
              if no check-in time is specified.
            </span>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
            2. Refund Amounts
          </h3>
          <div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Guests are eligible for a full refund if they cancel their
              reservation 48 hours before the check in date. The service fees
              will not be refunded.
            </span>
            <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
              3. Cancellation Process
            </h3>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              To initiate your cancellation process, contact our customer
              support team via Whatsapp. To understand the detailed breakdown of
              the refund, guests initiate the cancellation process, and we will
              provide comprehensive information.
            </span>
            <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
              4. Refund limitations
            </h3>
            <ul className="list-disc px-5 pl-10">
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                The amount refunded will never exceed the total amount paid by
                the guest at the time of cancellation.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                Refunds are processed in the same currency and method as the
                original payment.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
              5. Special Circumstances
            </h3>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              In cases of extenuating circumstances or events beyond the guests
              control, we may review the refund requests on a case by case
              basis. Guests who do not arrive for their reservation without
              canceling will not be eligible for a refund.
            </span>
            <div>
              <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
                6. Modification requests
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                Guests seeking to modify their reservation are encouraged to
                contact our customer support team for assistance. This is under
                Subject To Availability. Modifications may impact the refund
                eligibility (prices may differ from the original reservation)
              </span>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-neutral-900 mt-6 md:text-xl dark:text-neutral-200">
                7. Contact Information
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                For any questions or concerns regarding cancellation and
                refunds,
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {" "}
                please contact our customer support team at{" "}
                <Link to="/contact-us">
                  <span>
                    <b>Contact Us</b>
                  </span>
                </Link>
                .
              </span>
              <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
                <b>
                  Note: This refund policy applies to reservations made directly
                  through Duomo website. For reservations made through third
                  party platforms, please refer to the respective platform
                  policies.
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SectionHero);
