import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardCategory3 from "./CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import NcImage from "shared/NcImage/NcImage";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "Nature House",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "#",
    name: "Wooden house",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "#",
    name: "Houseboat",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "#",
    name: "Farm House",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "#",
    name: "Dome House",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3613236/pexels-photo-3613236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    id: "6",
    href: "#",
    name: "Dome House",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3613236/pexels-photo-3613236.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
];

const PropertyCardsSections: FC<SectionSliderNewCategoriesProps> = ({
  heading = "",
  subHeading = "Discover your perfect luxury stay in Dubai, anytime.",
  className = "",
  itemClassName = "",
  categories = DEMO_CATS,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
}) => {
  const Data = [
    {
      id: 1,
      name: "Enhanced Comfort and Productivity",
      content:
        "Experience superior comfort with spacious living areas, fully-equipped kitchens, and private bedrooms, fostering a productive work environment during corporate stays.",
      thumbnail: require("images/participant/1.png"),
    },
    {
      id: 2,
      name: "Cost-Efficiency for Extended Stays",
      content:
        "Optimize your budget with competitive rates and flexible terms for extended stays, providing a cost-effective solution for project assignments, training programs, and temporary relocations.",
      thumbnail: require("images/participant/3.png"),
    },
    {
      id: 3,
      name: "Tailored Amenities for Business Needs",
      content:
        "Enjoy business-centric amenities such as high-speed Wi-Fi, dedicated workspaces, and business-friendly facilities, ensuring a seamless blend of work and leisure for corporate travelers.",
      thumbnail: require("images/participant/4.png"),
    },
    {
      id: 4,
      name: "Personalized Service and Flexibility",
      content:
        "Benefit from our commitment to exceptional service, offering flexibility for group bookings and extended stays, providing a hassle-free and personalized experience for corporate partners.",
      thumbnail: require("images/participant/2.png"),
    },
  ];
  return (
    <div
      className={`nc-SectionSliderNewCategories ${className} pt-24 pb-8 mx-[3%]`}
    >
      <div className={`flow-root`}>
        {/* <div className="mt-4 flex flex-col justify-center px-5 mb-10">
          <h2
            className={` text-center text-[35px] font-[500] text-[#191E3B] leading-[35px]`}
          >
            Success in travel starts here
          </h2>
          <p className={` mt-2  text-[15px] text-center mt-6 text-[#676A7D]`}>
            Our industry-leading solutions and data-backed resources are
            designed to meet your needs and exceed your expectations.
          </p>
        </div> */}
        <div className="mt-14 mx-[3%] 2xl:mx-[5%]">
          {/* 1 */}
          <div className="flex flex-col lg:flex-row justify-between 2xl:justify-around">
            <div className="w-full lg:w-[45%]">
              <img
                src={require("images/corporateImg1.png")}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="w-full lg:w-[45%] pt-10">
              <h2
                className={`!leading-[32px] text-[26px]  font-[500] text-[#191E3B]`}
              >
                Exclusive Corporate Rates
              </h2>
              <p className={` mt-3 text-[15px] text-[#191E3B] !leading-[25px]`}>
                Embark on significant cost savings with our exclusive corporate
                rates, offering a compelling alternative to top-rated hotels in
                premium locations. Experience affordability without compromising
                on quality and convenience.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className="flex flex-col-reverse lg:flex-row justify-between 2xl:justify-around mt-16">
            <div className="w-full lg:w-[45%] pt-10">
              <h2
                className={`!leading-[32px] text-[26px]  font-[500] text-[#191E3B]`}
              >
                Flexibility and Support
              </h2>
              <p className={` mt-3 text-[15px] text-[#191E3B] !leading-[25px]`}>
                Our corporate partners experience flexibility with requested
                date extensions and cancellations, coupled with the assurance of
                24/7 support for you and your team. We prioritize your needs to
                ensure a seamless and accommodating experience.
              </p>
            </div>{" "}
            <div className="w-full lg:w-[45%]">
              <img
                src={require("images/corporateImg2.png")}
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          {/* 3 */}
          <div className="flex flex-col lg:flex-row justify-between 2xl:justify-around mt-16">
            <div className="w-full lg:w-[45%]">
              <img
                src={require("images/corporateImg3.png")}
                alt=""
                loading="lazy"
              />
            </div>
            <div className="w-full lg:w-[45%] pt-10">
              <h2
                className={`!leading-[32px] text-[26px]  font-[500] text-[#191E3B]`}
              >
                Elevate Productivity On the Go
              </h2>
              <p className={` mt-3 text-[15px] text-[#191E3B] !leading-[25px]`}>
                Each of our apartments is equipped with a dedicated workspace,
                empowering your team to stay productive and on top of work while
                away.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="grid grid-cols-4">
          {Data.map((item, index) => (
            <div
              className={`nc-CardCategory3 flex flex-col px-2 mb-4`}
              key={index}
            >
              <div
                className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0  overflow-hidden group`}
              >
                <NcImage
                  src={item?.thumbnail}
                  className="object-cover w-full h-full "
                />
                <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
              </div>
              <div className="mt-4">
                <h2
                  className={`text-base text-[15px] 2xl:text-[20px] text-neutral-900 dark:text-neutral-100 font-[400] truncate`}
                >
                  {item?.name}
                </h2>
                <p
                  className={` mt-2 text-[13px] 2xl:text-[15px] text-neutral-6000 dark:text-neutral-400`}
                >
                  {item.content}
                </p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
      {/* <div className="flex justify-center pt-16 pb-8">
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className=" text-white bg-[#612ACE] hover:opacity-[0.9] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[15px] text-sm px-[40px] py-[12px] text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Apply To Join Our Collection
        </button>
      </div> */}
    </div>
  );
};

export default PropertyCardsSections;
