import clientSay1 from "images/help-center.png";
const HelpCenterHeroimg = () => {
  return (
    <div className="w-full relative">
      <div className="relative">
        <div className="absolute w-full h-full bg-[rgba(0,0,0,0.6)] z-20">
          <div className="flex justify-center items-center h-full">
            <h2 className="lg:text-[60px] md:text-[40px] text-[30px] leading-[66px] text-[#fff] font-medium">
            Help Center
            </h2>
          </div>
        </div>
        <img className="w-full h-full" src={clientSay1} alt="" />
      </div>
      <div className="w-full h-full flex items-center justify-center relative z-[30]">
        <div className="bg-[#fff] px-4 sm:px-6 lg:px-8 pt-12 pb-5 rounded-[6px] w-full sm:w-3/4 md:w-2/3 lg:w-[60%] 2xl:w-[50%] relative z-10 md:bottom-[130px] flex flex-col justify-center">
          <div>
            <div>
              <h6 className="font-[500] text-[12px] text-[#777E90] text-center">
                Learn how to get started
              </h6>
              <h1 className="text-[#23262F] font-[500] text-center text-[32px] lg:text-[48px] lg:py-4 relative md:leading-[24px] leading-[35px]">
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterHeroimg;
