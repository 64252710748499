import React, { FC, ReactNode, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import Heading from "components/Heading/Heading";

const SectionHero = (props: any) => {
  const { className = "", history } = props;
  const myRef: any = useRef(null);
  // console.log("history", history);
  // const executeScroll = () =>
  //   myRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //     inline: "center",
  //   });
  // useEffect(() => {
  //   console.log("test", history?.location?.search === "?key=cancelation");
  //   myRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //     inline: "center",
  //   });
  // }, []);
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full">
          {/* <h2 className="font-semibold capitalize text-neutral-900 dark:text-white text-base">
            <span className="">Guideline and Regulations</span>
          </h2> */}
          <h2 className="text-3xl font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 pt-2">
          PRIVACY POLICY
          </h2>{" "}
        </div>
      </div>
      <div className="pt-10">
        {/* Terms of Use content start Here */}
        <div>
          {/* <h3 className="text-xl font-semibold text-neutral-900 mt-2 md:text-xl dark:text-neutral-200">
            Terms of Use
          </h3> */}
          <div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Duomo ("we," "us," or "our") is committed to protecting the
              privacy of our guests. We are a community built on trust and a
              fundamental part of earning that trust means being clear about how
              we use your information and protecting your human right to
              privacy. We’re committed to protecting and safeguarding your
              personal data. We act in our customers’ best interests and are
              transparent about the processing of your personal data.
            </span>
          </div>
          <div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              This document (“The Privacy Policy” or “Our Privacy Policy”)
              explains what personal information we collect, how we use personal
              information and how personal information is shared. It also tells
              you what rights you can exercise in relation to your personal data
              and how you can contact us.
            </span>
          </div>
          <div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              By using our holiday home services, you consent to the practices
              described in this Privacy Policy
            </span>
          </div>
        </div>
        {/* Payment content */}
        <div>
          <h3 className="text-xl font-semibold text-neutral-900 mt-8 md:text-xl dark:text-neutral-200">
            1. DEFINITIONS
          </h3>
          <div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Undefined terms in this Privacy Policy have the same definition as
              in our Terms of Service (“Terms”).
            </span>
          </div>
        </div>
        {/* Cancellation and Refunds */}
        <div>
          <h3 className="text-xl font-semibold text-neutral-900 mt-8 md:text-xl dark:text-neutral-200">
            2. PERSONAL INFORMATION WE COLLECT
          </h3>
          <div>
            <span className="block mt-2 text-[#000] font-medium">
              2.1 Information Needed to Use the Duomo Platform.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              We collect personal information about you when you use the Duomo
              Platform. Without it, we may not be able to provide all services
              requested. This information may includes:
            </span>
            <ul className="list-disc px-5 pl-10">
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Contact Information:</span> First
                name, last name, phone number, postal address, email address,
                date of birth, and profile photo, some of which will depend on
                the features you use.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Reservation Information:</span>{" "}
                Details about your reservation, including dates of stay, room
                preferences, and special requests.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">
                  Dentity Verification and Payment Information:
                </span>{" "}
                Images of your government-issued ID (as permitted by applicable
                laws), your ID number or other verification information. If a
                copy of your ID is provided to us, we may scan, use, and store
                information contained in your ID to verify your identity and for
                security purposes. Credit card/bank details and billing
                information for reservation and payment processing.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Guest Preferences:</span>{" "}
                Information regarding your preferences and special requests to
                enhance your stay.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Communication:</span> Records of
                correspondence when you contact us, including emails and other
                forms of communication.
              </li>
            </ul>
            <span className="block mt-2 text-[#000] font-medium">
              2.2 Information You Choose to Give Us.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              You can choose to provide us with additional personal information.
              This information may include:
            </span>
            <ul className="list-disc px-5 pl-10">
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">
                  Additional Profile Information:
                </span>{" "}
                Such as gender, preferred language(s), city, and personal
                description. Some of this information as indicated in your
                account settings is part of your public profile page and will be
                publicly visible.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Information About Others:</span>{" "}
                Such as a payment instrument belonging to another person or
                information about a co-traveler. By providing us with personal
                information about others, you certify that you have permission
                to provide that information to Duomo for the purposes described
                in this Privacy Policy and you have shared the Duomo Privacy
                Policy with them.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Other Information:</span> Such as
                when you fill in a form, add information to your account,
                respond to surveys, post to community forums, participate in
                promotions, communicate with Duomo Support and other Members,
                import or manually enter address book contacts, provide your
                address and/or geolocation, or share your experience with us.
                This may include health information if you choose to share it
                with us.
              </li>
            </ul>
            <span className="block mt-2 text-[#000] font-medium">
              2.3 Information Automatically Collected by Using the Duomo
              Platform and Our Payment Services.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              When you use the Duomo Platform and Payment Services, we
              automatically collect personal and other information. This
              information may include:
            </span>
            <ul className="list-disc px-5 pl-10">
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Usage Information:</span> Such as
                the pages or content you view, searches for Listings, bookings
                you have made, additional services you have added, and other
                actions on the Duomo Platform.
              </li>
            </ul>
            <h3 className="text-xl font-semibold text-neutral-900 mt-8 md:text-xl dark:text-neutral-200">
              3. UTILIZATION OF INFORMATION
            </h3>
            {/* <span className="block mt-2 text-[#000] font-medium">
              3.0 Provide, Personalize, Measure, and Improve our Advertising and
              Marketing. We may process this information to:
            </span> */}
            <span className="block mt-2 text-[#000] font-medium">
              3.1 How we use your information
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              We use the collected information for the following purposes:
            </span>
            <ul className="list-disc px-5 pl-10">
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Reservation Processing:</span> To
                process and manage your reservation.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Customer Service:</span> To
                provide customer support and respond to inquiries.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Improving Services:</span> To
                enhance our services and tailor them to guest preferences.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Marketing:</span> With your
                consent, to send promotional materials and updates about our
                holiday homes
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">Legal Requirements: </span>
                To comply with legal obligations.
              </li>
            </ul>
            <span className="block mt-2 text-[#000] font-medium">
              3.2 Information Sharing.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              We do not sell, trade, or otherwise transfer your personal
              information to third parties without your consent, except as
              described in this Privacy Policy.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              We may share your information with:
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Service providers and partners involved in providing our services.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Legal authorities if required to comply with the law.
            </span>
            <span className="block mt-2 text-[#000] font-medium">
              3.3 Provide, Personalize, Measure, and Improve our Advertising and
              Marketing.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              We may utilize this information for the purpose of delivering
              promotional messages, marketing, advertising, and other relevant
              content tailored to your preferences on various platforms,
              including social media. Additionally, we may personalize,
              evaluate, and enhance our advertising efforts, oversee referral
              programs, rewards, surveys, sweepstakes, contests, or other
              promotional activities sponsored or managed by Duomo or its
              third-party partners. Our analysis of characteristics and
              preferences aims to deliver promotional messages, marketing,
              advertising, and other information that aligns with your
              interests. You may also receive invitations to events and relevant
              opportunities. With your explicit consent, we may send promotional
              messages, marketing, advertising, and other information that is
              likely to be of interest to you based on your preferences.
            </span>

            <span className="block mt-2 text-[#000] font-medium">
              3.4 Service Providers.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              We share personal information with affiliated and unaffiliated
              service providers (including their service providers) to help us
              run our business and for their compliance purposes, including
              those that help us:
            </span>
            <div className="pl-5">
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(i)</span> Verify your identity or
                authenticate your identification documents.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(ii)</span> Check information
                against public databases.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(iii)</span> Conduct background
                checks, fraud prevention, security investigations, and risk
                assessments.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(iv)</span> Perform product
                development, maintenance, and debugging.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(v)</span> Allow the provision of
                the Duomo Services through third-party platforms and software
                tools (e.g., through the integration with our APIs).
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(vi)</span> Provide customer
                service, advertising, or payments services.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(vii)</span> Offer additional
                services you select.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(viii)</span> Process, handle, or
                assess insurance claims or similar claims.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(ix) </span> Review, scan, and
                analyze communications on the Duomo Platform for certain
                purposes (such as evidence of child exploitation).
              </span>
            </div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              See the Analyzing and Sharing Your Communications section for
              additional information. These providers are contractually bound to
              protect your personal information and have access to your personal
              information to perform these tasks. Other Members can use services
              other than Duomo to process your data. These can include email or
              reservation management software. Such services are outside of
              Duomo’s control and will be subject to applicable laws around the
              world with varying levels of data protection.
            </span>
            <span className="block mt-2 text-[#000] font-medium">
              3.5 User’s rights.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              You have the right to:
            </span>
            <ul className="list-disc px-5 pl-10">
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                Review and update your personal information.
              </li>
              <li className=" mt-2 text-neutral-500 dark:text-neutral-400">
                Opt-out of marketing communications.
              </li>
            </ul>

            <h3 className="text-xl font-semibold text-neutral-900 mt-8 md:text-xl dark:text-neutral-200">
              4. LEGAL
            </h3>
            {/* <span className="block mt-2 text-[#000] font-medium">
              4.0 Data Access and Portability.
            </span> */}
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Complying with Law, Responding to Legal Requests, Preventing Harm
              and Protecting Our Rights. We may disclose your information to
              courts, law enforcement, governmental or public authorities, tax
              authorities, authorized third parties, or other Members, if and to
              the extent we are required or permitted to do so by law or where
              disclosure is reasonably necessary to:
            </span>
            <div className="pl-5">
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(i)</span> Comply with our legal
                obligations.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(ii)</span> Comply with a valid
                legal request (such as a subpoena or court order) or to respond
                to claims asserted against Duomo.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(iii)</span> Respond to a valid
                legal request relating to a criminal investigation to address
                alleged or suspected illegal activity, or to respond to or
                address any other activity that may expose us, you, or any other
                of our users to legal or regulatory liability (more information
                on Duomo’s Law Enforcement Guidelines here).
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(iv)</span> Enforce and administer
                our agreements with Members, including our Terms, Additional
                Legal Terms, and Policies.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(v)</span> Protect the rights,
                property or personal safety of Duomo, its employees, its
                Members, or members of the public.
              </span>
            </div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Where appropriate, we may notify Members about legal requests
              unless:
            </span>
            <div className="pl-5">
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(i)</span> Providing notice is
                prohibited by the legal process itself, by court order we
                receive, or by applicable law.
              </span>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <span className="font-medium">(ii)</span> We believe that
                providing notice would be futile, ineffective, create a risk of
                injury or bodily harm to an individual or group, or create or
                increase a risk of fraud upon or harm to Duomo, our Members, or
                expose Duomo to a claim of obstruction of justice.
              </span>
            </div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Where legally required or permissible according to applicable law,
              we may disclose Hosts’ and/or Guests’ information to tax
              authorities or other governmental agencies for the purpose of the
              tax authorities’ determination of proper compliance with relevant
              tax obligations. Relevant tax obligations include Duomo tax
              obligations on its service fees, its facilitation of taxes on
              accommodations and withholding taxes, and Hosts’ individual tax
              obligations. Information that may be disclosed includes, but is
              not limited to, Host and Guest names, listing addresses, Host
              addresses, tax/business identification number(s), date of birth,
              and/or contact information, property parcel identification
              numbers, payout information, transaction dates and amounts, number
              of nights and Guests booked, gross and net booking value and
              payout amounts, taxes collected by Duomo on behalf of Guests and
              Hosts, to the extent any of this information is known by Duomo.
            </span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              In jurisdictions where Duomo facilitates or requires a
              registration, notification, permit, or license application or
              number of a Host with a governmental authority, we may share
              information of participating Hosts with the relevant authority,
              both during the application process, when the Listing is
              published, and periodically thereafter, such as the Host’s full
              name and contact details, Accommodation address, tax
              identification number, registration, permit, or license number,
              Listing details, reservation information, and number of nights
              booked subject to applicable laws.
            </span>
            <h3 className="text-xl font-semibold text-neutral-900 mt-8 md:text-xl dark:text-neutral-200">
              5. SECURITY
            </h3>
            {/* <span className="block mt-2 text-[#000] font-medium">
              5. SECURITY
            </span> */}
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              While no organization can guarantee perfect security, we are
              continuously implementing and updating administrative, technical,
              and physical security measures to help protect your information
              against unlawful or unauthorized access, loss, destruction, or
              alteration.
            </span>
          </div>
          <div className="pt-10" ref={myRef} id="cancelation">
            {/* Terms of Use content start Here */}
            <div>
              <h3 className="text-xl font-semibold text-neutral-900 mt-2 md:text-xl dark:text-neutral-200">
                6. CHANGES TO THIS PRIVACY POLICY
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                We reserve the right to update this Privacy Policy periodically.
                Any changes will be posted on our website.
              </span>
            </div>
            {/* Payment content */}

            {/* Cancellation and Refunds */}

            <div className="pt-6">
              <div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  Contact Us -
                </span>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  If you have questions about this Privacy Policy or your
                  personal information, please contact us at <Link to="/contact-us"><span><b>Contact Us</b></span></Link>.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SectionHero);
