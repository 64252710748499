import React, { FC, useContext } from "react";
import { TaxonomyType } from "data/types";
import StayCard from "components/StayCard/StayCard";
import { allListings } from "utils/listings";
import { GlobalContext } from "context/globalContext/globalContext";
import Carousel from "react-multi-carousel";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  className = "",
}) => {
  const { state } = useContext(GlobalContext);

  const renderCard2 = (stay: any) => {
    return <StayCard key={stay.id} data={stay} />;
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1700 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1700, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };

  // const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  //   const {
  //     carouselState: { currentSlide },
  //   } = rest;
  //   return (
  //     <div
  //       className={`nc-NextPrev  flex items-center text-neutral-900 dark:text-neutral-300  carousel-button-group absolute w-full flex justify-between ${className}`}
  //       data-nc-id="NextPrev"
  //       data-glide-el="controls"
  //     >
  //       <button
  //         className={`\  left-button bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 p-3`}
  //         // onClick={onClickPrev}
  //         onClick={() => previous()}
  //         title="Prev"
  //         data-glide-dir="<"
  //       >
  //         <i className="las la-angle-left"></i>
  //       </button>
  //       <button
  //         className={` right-button bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 p-3`}
  //         // onClick={onClickNext}
  //         onClick={() => next()}
  //         title="Next"
  //         data-glide-dir=">"
  //       >
  //         <i className="las la-angle-right"></i>
  //       </button>
  //     </div>
  //   );
  // };

  return (
    <div
      className={`nc-SectionSliderNewCategories ${className} mx-[3%] lg:mx-[5%] `}
    >
      {/* <div className={`nc-Section-Heading relative`}>
        <div className="mx-[3%] mx-[9%] mt-10">
          <h2
            className={`text-[25px] lg:text-[33px] lg:px-10 2xl:px-20 text-[#383838] text-center font-[500] leading-[35px] `}
          >
            Here’s just a few of the properties that we’ve designed, furnished &
            rented through our leasing partnerships
          </h2>
        </div>
      </div> */}
      {/* <div className="mt-32 px-2 mx-[3%]">
        <Carousel
          responsive={responsive}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          className="listing-carousel-container"
        >
          {allListings(state?.roomListings)
            .splice(0, 3)
            .map((item: any) => renderCard2(item))}
        </Carousel>
      </div> */}
      <div className=" px-2 mx-[3%]">
        <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
          {allListings(state?.roomListings)
            .splice(0, 3)
            .map((item: any) => renderCard2(item))}
        </div>
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
