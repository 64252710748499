import { PostAPIService } from "context/services";
import React, { useState } from "react";
import { Button, notification } from "antd";
import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Barsha Heights, Dubai, United Arab Emirates",
  },
  {
    title: "💌 EMAIL",
    desc: "info@myduomo.com",
  },
  {
    title: "☎ PHONE",
    desc: "+971 50 1510743",
  },
];

const PageContact: any = ({ className = "" }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message: any, description: any) => {
    api.open({
      message: message,
      description: description,
      duration: 0,
    });
  };

  const [user, setUser]: any = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const onChangeHandler = (key: any, value: any) => {
    let duplicate: any = { ...user };
    duplicate[key] = value;
    setUser(duplicate);
  };
  console.log("user", user);
  // handler payment
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      full_name: user?.name,
      email: user?.email,
      phone_number: user?.number,
      contact_subject: user?.subject,
      contact_message: user?.message,
    };
    PostAPIService("/form/contact/submit", payload)
      .then((response) => {
        console.log("response", response);
        setLoader(false);
        setUser({
          name: "",
          email: "",
          number: "",
          subject: "",
          message: "",
        });
        openNotification("Success", "Thank You our team will get back to you at the earliest.");
      })
      .catch((error) => {
        console.log("response", error);
        setLoader(false);
        openNotification("Error", "");
      });
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <div className="mb-24 lg:mb-28">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          {contextHolder}
          <div className="flex-shrink-0 md:grid md:grid-cols-2 flex flex-col-reverse gap-12">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            <div>
              <form
                className="grid grid-cols-1 gap-6"
                onSubmit={(e) => onSubmitHandler(e)}
              >
                <div className="flex flex-col lg:flex-row w-full lg:space-x-2">
                  <label className="block w-full lg:w-1/2">
                    <Label>Full name</Label>

                    <Input
                      placeholder="Name"
                      type="text"
                      className="mt-1"
                      required
                      value={user?.name}
                      onChange={(e) => onChangeHandler("name", e.target.value)}
                    />
                  </label>
                  <label className="block w-full lg:w-1/2 mt-6 lg:mt-0">
                    <Label>Email address</Label>

                    <Input
                      type="email"
                      placeholder="Email"
                      className="mt-1"
                      required
                      value={user?.email}
                      onChange={(e) => onChangeHandler("email", e.target.value)}
                    />
                  </label>
                </div>
                <label className="block">
                  <Label>Phone</Label>

                  <Input
                    type="number"
                    placeholder="Phone"
                    className="mt-1"
                    required
                    value={user?.number}
                    onChange={(e) => onChangeHandler("number", e.target.value)}
                  />
                </label>
                <label className="block">
                  <Label>Subject</Label>

                  <Input
                    type="text"
                    placeholder="Subject"
                    className="mt-1"
                    required
                    value={user?.subject}
                    onChange={(e) => onChangeHandler("subject", e.target.value)}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    required
                    value={user?.message}
                    onChange={(e) => onChangeHandler("message", e.target.value)}
                  />
                </label>
                <div>
                  <ButtonPrimary disabled={loader} type="submit">
                    Send Message
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative mb-24 ">
          {/* <BackgroundSection />
          <SectionClientSay /> */}
          <div className="w-full">
            <iframe
              width="100%"
              height="600"
              // frameborder="0"
              // scrolling="no"
              // marginheight="0"
              // marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Grosvenor%20Business%20Tower+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              className=""
            >
              <a href="https://www.maps.ie/population/">Population mapping</a>
            </iframe>
          </div>
        </div>
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
      </div>
    </div>
  );
};

export default PageContact;
