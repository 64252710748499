import React, { useState } from "react";
import { PostAPIService } from "context/services";
import { notification } from "antd";
import clientSay1 from "images/contactpage.png";
import Email from "images/vectors/email.svg";
import Phone from "images/vectors/phone.png";
import Address from "images/vectors/location.png";
import LazyLoad from "react-lazy-load";
const OurServicesMain = () => {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message: any, description: any) => {
    api.open({
      message: message,
      description: description,
      duration: 0,
    });
  };

  const [user, setUser]: any = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const onChangeHandler = (key: any, value: any) => {
    let duplicate: any = { ...user };
    duplicate[key] = value;
    setUser(duplicate);
  };
  console.log("user", user);
  // handler payment
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      full_name: user?.name,
      email: user?.email,
      phone_number: user?.number,
      contact_subject: "-",
      contact_message: user?.message,
    };
    PostAPIService("/form/contact/submit", payload)
      .then((response) => {
        console.log("response", response);
        setLoader(false);
        setUser({
          name: "",
          email: "",
          number: "",
          subject: "",
          message: "",
        });
        openNotification(
          "Success",
          "Thank You our team will get back to you at the earliest."
        );
      })
      .catch((error) => {
        console.log("response", error);
        setLoader(false);
        openNotification("Error", "");
      });
  };
  return (
    <div className="w-full">
      <div className="relative">
        {contextHolder}
        <div className="absolute w-full h-full bg-[rgba(0,0,0,0.6)] z-20">
          <div className="flex justify-center items-center h-full">
            <h2 className="lg:text-[60px] md:text-[40px] text-[30px] leading-[66px] text-[#fff] font-medium">
              We’re here to help
            </h2>
          </div>
        </div>
        <LazyLoad offset={300}>
          <img
            src={clientSay1}
            alt=""
            loading="lazy"
            className="w-full h-full"
          />
        </LazyLoad>
      </div>
      <div className="w-full h-full flex items-center justify-center z-[30] relative">
        <div className="px-4 sm:px-6 lg:px-8 pt-12 relative z-10 lg:bottom-[130px] flex flex-col justify-center items-center w-full">
          <div className="w-full lg:w-[80%] flex flex-col lg:flex-row justify-between lg:mb-0 mb-12 ">
            {/* left side form */}
            <div className="bg-[#fff] w-full lg:w-[68%] xl:w-[65%] rounded-[6px] md:p-7 p-6 lg:shadow-lg ">
              <h2 className="text-[24px] font-normal leading-[29px] text-[#00000] py-2">
                Contact Us
              </h2>
              <div>
                <form onSubmit={(e) => onSubmitHandler(e)}>
                  <div className="flex md:flex-row flex-col justify-between pt-7 ">
                    <div className="md:w-[32%] w-full md:mb-0 mb-6">
                      <input
                        placeholder="Name*"
                        type="text"
                        className="w-full rounded-[6px] border-[#D9E5E9] py-3 !text-[16px] text-[#696969]"
                        required
                        value={user?.name}
                        onChange={(e) =>
                          onChangeHandler("name", e.target.value)
                        }
                      />
                    </div>

                    <div className="md:w-[32%] w-full md:mb-0 mb-6">
                      <input
                        placeholder="Email*"
                        type="email"
                        className="w-full rounded-[6px] border-[#D9E5E9] py-3 !text-[16px] text-[#696969]"
                        required
                        value={user?.email}
                        onChange={(e) =>
                          onChangeHandler("email", e.target.value)
                        }
                      />
                    </div>

                    <div className="md:w-[32%] w-full">
                      <input
                        placeholder="Phone*"
                        type="number"
                        className="w-full rounded-[6px] border-[#D9E5E9] py-3 !text-[16px] text-[#696969]"
                        required
                        value={user?.number}
                        onChange={(e) =>
                          onChangeHandler("number", e.target.value)
                        }
                      />
                      {/* <select className="w-full rounded-[6px] border-[#D9E5E9] py-3 !text-[16px]">
                        <option value="volvo">Reasons</option>
                        <option value="Hello">Hello</option>
                        <option value="Hi Everyone">Hi Everyone</option>
                        <option value="Hello Everyone">Hello Everyone</option>
                      </select> */}
                    </div>
                  </div>

                  <div className="pt-7 pb-6">
                    <textarea
                      className="w-full rounded-[6px] border-[#D9E5E9] py-3 text-[16px] text-[#696969]"
                      placeholder="Message"
                      rows={7}
                      value={user?.message}
                      onChange={(e) =>
                        onChangeHandler("message", e.target.value)
                      }
                    />
                  </div>
                  <div className="flex justify-center bg-[#612ACE] rounded-[16px]">
                    <button
                      disabled={loader}
                      type="submit"
                      className="text-[#fff] py-4 font-medium text-[17px]"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* right side details */}
            <div className="bg-[#fff] w-full lg:w-[30%] xl:w-[33%]  rounded-[6px] md:p-7 p-6 lg:shadow-lg ">
              <h2 className="text-[24px] font-normal leading-[29px] text-[#00000] py-2">
                Contact Info
              </h2>
              <h4 className="text-[15px] font-bold leading-[29px] text-[#612ACE] py-2">
                Duomo Tourism LLC
              </h4>
              <div className="flex mt-2">
                <div className="p-2">
                  <img src={Email} alt="" className="h-[24px]" />
                </div>
                <div className="pl-1">
                  <h4 className="text-[13px] font-semibold text-[#000]">
                    Email
                  </h4>
                  <h5 className="text-[13px] font-normal text-[#612ACE]">
                    info@myduomo.com
                  </h5>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="p-2">
                  <img src={Phone} alt="" className="h-[24px]" />
                </div>
                <div className="pl-1">
                  <h4 className="text-[13px] font-semibold text-[#000]">
                    Phone
                  </h4>
                  <h5 className="text-[13px] font-normal text-[#612ACE]">
                    +971 50 1510743
                  </h5>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="p-2">
                  <img src={Address} alt="" className="h-[24px]" />
                </div>
                <div className="pl-1">
                  <h4 className="text-[13px] font-semibold text-[#000]">
                    Address
                  </h4>
                  <h5 className="text-[13px] font-normal text-[#612ACE]">
                    Barsha Heights, Dubai, United Arab Emirates
                  </h5>
                </div>
              </div>
              <div className="w-full h-[158px] border-[1px] border-[#D9E5E9] mt-4 rounded-[5px]  ">
                <iframe
                  width="100%"
                  height="600"
                  src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=Grosvenor%20Business%20Tower+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  className="h-[158px]"
                >
                  <a href="https://www.maps.ie/population/">
                    Population mapping
                  </a>
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServicesMain;
