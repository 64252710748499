import React from "react";
import ReactPlayer from "react-player/lazy";
import ApplyToJoinCard from "./ApplyToJoinCard";

const PropertyHero = () => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/duomoproperty.mp4";

  return (
    <div className="relative">
      <ReactPlayer
        url={video}
        controls={false}
        loop
        playing
        playsinline={true}
        muted={true}
        className="!w-[100%] !h-[100%] absolute"
        // onReady={() => {
        //   console.log("load");
        // }}
      />
      <div className="bg-[rgba(0,0,0,0.5)] w-full pb-12 lg:pb-0 relative z-20 ">
        <div className="flex flex-col lg:flex-row lg:flex-row xl:flex-row justify-center mx-[3%] lg:mx-[3%] xl:[4%] 2xl:[5%]">
          <div className="w-full lg:w-[50%] xl:w-[45%] pt-8 pb-8 flex justify-center flex-col md:pr-12">
            <h1 className="text-4xl lg:text-5xl 2xl:text-[60px] font-[500] text-white pt-2 pb-2 lg:!leading-[60px]">
              Join our exclusive collection
            </h1>
            <p className="text-[19px] font-[500]  text-white font-light pt-4 pb-2 lg:!leading-[30px] pr-0 lg:pr-10">
              Where Corporate Partnerships take center stage in our journey to
              redefine the world of premium accommodation. What sets us apart is
              our commitment to curating an exclusive portfolio of holiday
              homes, strategically located in prime destination. Each property
              is selected with meticulous care, ensuring it meets the high
              standards expected by our corporate partners. At Duomo, we provide
              exceptional service, Personalized attention, and an unwavering
              commitment to making every stay memorable. Creating a legacy of
              unparalleled hospitality in the world of holiday homes.
            </p>
          </div>
          <div className="flex justify-center w-full lg:w-[50%] 2xl:w-[50%] lg:pt-10 lg:mb-[-40px]">
            <div className="lg:w-[100%] xl:w-[93%] 2xl:w-[70%]">
              <ApplyToJoinCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyHero;
