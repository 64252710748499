import React, { FC } from "react";

export interface StayCardProps {
  className?: string;

  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({ size = "default", className = "" }) => {
  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <div className="image-container">{/* placeholder div */}</div>
    </div>
  );
};

export default StayCard;
