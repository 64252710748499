import NavigationItem from "./NavigationItem";
// import ncNanoId from "utils/ncNanoId";

function Navigation() {
  const navigations = [
    {
      id: 1,
      href: "/property-owner",
      name: "Property Owner",
      img: require("images/ph_key.png"),
    },
    {
      id: 2,
      href: "/services",
      name: "Our Services",
      img: require("images/headerIcons/carbon_location-company.png"),
    },
    {
      id: 3,
      href: "/listing-stay",
      name: "Properties",
      img: require("images/headerIcons/carbon_home.png"),
    
    },
    {
      id: 4,
      href: "/corporate-partner",
      name: "Corporate Partners",
      img: require("images/headerIcons/carbon_partnership.png"),
    },
    
    {
      id: 5,
      href: "/about-us",
      name: "About Us",
      img: require("images/headerIcons/about.png"),
    },
    {
      id: 6,
      href: "/contact-us",
      name: "Contact",
      img: require("images/contactIcon.png"),
    },
  ];

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {navigations.map((item: any) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
