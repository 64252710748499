import React from "react";
import ApplyToJoinCard from "./ApplyToJoinCard2";
import ReactPlayer from "react-player/lazy";

const PropertyHero = () => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/duomoproperty.mp4";
  return (
    <div>
      {/* background img */}
      <div className="relative">
        <ReactPlayer
          url={video}
          controls={false}
          loop
          playing
          playsinline={true}
          muted={true}
          className="!w-[100%] !h-[100%] absolute"
          // onReady={() => {
          //   console.log("load");
          // }}
        />
        <div className="bg-[rgba(0,0,0,0.5)] w-full pb-12 lg:pb-0 relative z-20 ">
          <div className="flex flex-col lg:flex-row lg:flex-row xl:flex-row justify-center mx-[3%] lg:mx-[3%] xl:[4%] 2xl:[5%]">
            <div className="w-full lg:w-[50%] xl:w-[45%] pt-8 pb-8 flex justify-center flex-col md:pr-12">
              <h1 className="text-4xl lg:text-5xl 2xl:text-[60px] font-[500] text-white pt-2 pb-2 lg:!leading-[60px]">
                Let's Collaborate
              </h1>
              <p className="text-[19px] font-[500]  text-white font-light pt-4 pb-2 lg:!leading-[30px] pr-0 lg:pr-10">
                Duomo proves to be an invaluable partner for property owners
                through a multifaced approach. We partner with only the most
                exceptional homes in prime neighbourhoods that will delight our
                discerning guests.
              </p>
            </div>
            <div className="flex justify-center w-full lg:w-[50%] 2xl:w-[50%] lg:pt-10 lg:mb-[-40px]">
              <div className="lg:w-[100%] xl:w-[93%] 2xl:w-[70%]">
                <ApplyToJoinCard />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <div className=" w-full pb-12 lg:pb-0 relative z-20 ">
          <div className="flex flex-col lg:flex-row lg:flex-row xl:flex-row justify-center mx-[3%] lg:mx-[3%] xl:[4%] 2xl:[5%]">
            <div className="w-full lg:w-[50%] xl:w-[45%] pt-8 pb-8 flex flex-col md:pr-12">
              <h1 className="text-[30px] lg:text-[30px] 2xl:text-[35px] font-[500] pt-2 pb-2 lg:!leading-[40px] 2xl:!leading-[60px] leading-[45px]">
                Duomo, your rental property vacation management at your service
              </h1>
              <p className="text-[14px] font-[400]  font-light pt-4 pb-2 lg:!leading-[30px] pr-0 lg:pr-10">
                We know the considerable stress that hosting can bring, that’s
                why we offer comprehensive management services to ensure that
                you never have to steer the complications of hosting alone.
                Duomo is more than a service; we are a team of experts tailoring
                strategies to meet your needs. Our approach blends technology
                with experience, resulting in 20 - 30% rental income increase as
                compared to traditional leasing. Let Duomo’s Management service
                manage your stress and give you the freedom to enjoy what you
                love.
              </p>
            </div>
            <div className="flex justify-center w-full lg:w-[50%] 2xl:w-[50%] lg:pt-10 lg:mb-[-40px]">
              <div className="w-[100%] xl:w-[93%] 2xl:w-[70%]">
                <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-4 lg:p-5 xl:p-8 dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex">
                    <div className="w-[10%] sm:w-fit">
                      <img
                        src={require("images/listProperty/tick-green.png")}
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="w-[90%] sm:w-fit">
                      <p className="text-[16px] font-[400] text-[#212223] pt-1 px-2">
                        Daily price adjustments
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start py-6">
                    <div className="w-[10%] sm:w-fit">
                      <img
                        src={require("images/listProperty/tick-green.png")}
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="w-[90%] sm:w-fit">
                      <p className="text-[16px] font-[400] text-[#212223] pt-1 px-2">
                        Attractive listing creations and professional
                        photographs.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-[10%] sm:w-fit">
                      <img
                        src={require("images/listProperty/tick-green.png")}
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="w-[90%] sm:w-fit">
                      <p className="text-[16px] font-[400] text-[#212223] pt-1 px-2">
                        Style & design your home.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start py-6">
                    <div className="w-[10%] sm:w-fit">
                      <img
                        src={require("images/listProperty/tick-green.png")}
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="w-[90%] sm:w-fit">
                      <p className="text-[16px] font-[400] text-[#212223] pt-1 px-2">
                        Guest communications 24/7.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="w-[10%] sm:w-fit">
                      <img
                        src={require("images/listProperty/tick-green.png")}
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="w-[90%] sm:w-fit">
                      <p className="text-[16px] font-[400] text-[#212223] pt-1 px-2">
                        Maintenance and assistance
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start py-6">
                    <div className="w-[10%] sm:w-fit">
                      <img
                        src={require("images/listProperty/tick-green.png")}
                        alt=""
                        className="h-7"
                      />
                    </div>
                    <div className="w-[90%] sm:w-fit">
                      <p className="text-[16px] font-[400] text-[#212223] pt-1 px-2">
                        Provide projection of expected income.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyHero;
