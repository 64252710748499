import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import { withRouter } from "react-router-dom";
import Filter from "containers/ListingStayMap/Filters";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "Dubai, UAE";
const defaultDateRange = {
  startDate: moment(),
  endDate: moment().add(4, "days"),
};
const defaultGuestValue: GuestsInputProps["defaultValue"] = {
  guestAdults: 2,
  guestChildren: 2,
  guestInfants: 1,
};

const StaySearchForm = ({ haveDefaultValue = false, history }: any) => {
  const [dateRangeValue, setDateRangeValue]: any = useState<DateRage>({
    startDate: null,
    endDate: null,
  });
  const [locationInputValue, setLocationInputValue] = useState("Dubai");
  const [guestValue, setGuestValue]: any = useState({});
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  //
  useEffect(() => {
    if (haveDefaultValue) {
      setDateRangeValue(defaultDateRange);
      setLocationInputValue(defaultLocationValue);
      setGuestValue(defaultGuestValue);
    }
  }, []);

  const onSubmitHandler = () => {
    // 21-12-2023
    const startDate = new Date(dateRangeValue?.startDate?._d);
    const endDate = new Date(dateRangeValue?.endDate?._d);
    // link for redirect
    // window.open(
    //   `https://duomo.hostify.club/listings?start_date=${
    //     dateRangeValue?.startDate !== null
    //       ? startDate.getDate() +
    //         "-" +
    //         (startDate.getMonth() + 1) +
    //         "-" +
    //         startDate.getFullYear()
    //       : ""
    //   }&end_date=${
    //     dateRangeValue?.endDate !== null
    //       ? endDate.getDate() +
    //         "-" +
    //         (endDate.getMonth() + 1) +
    //         "-" +
    //         endDate.getFullYear()
    //       : ""
    //   }&bedrooms=&guests=${
    //     guestValue?.guestAdults +
    //     guestValue?.guestChildren +
    //     guestValue?.guestInfants
    //   }&adults=${guestValue?.guestAdults}&children=${
    //     guestValue?.guestChildren
    //   }&infants=${guestValue?.guestInfants}`
    // );
  };

  const renderForm = () => {
    return (
      <>
        {/* filters */}
        <Filter
          isOpenMoreFilter={isOpenMoreFilter}
          setisOpenMoreFilter={setisOpenMoreFilter}

        />
        <form className="w-full relative mt-8 flex flex-col md:flex-row md:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 md:divide-y-0 boxshadow-container">
          <LocationInput
            defaultValue={locationInputValue}
            onChange={(e) => setLocationInputValue(e)}
            onInputDone={() => setDateFocused("startDate")}
            disabled={true}
          
          />
          <StayDatesRangeInput
            defaultValue={dateRangeValue}
            defaultFocus={dateFocused}
            onFocusChange={(focus) => setDateFocused(focus)}
            onChange={(data) => setDateRangeValue(data)}
          />
          <GuestsInput
            defaultValue={guestValue}
            onChange={(data) => setGuestValue(data)}
          />
          {/* BUTTON SUBMIT OF FORM */}
          <div className="px-4 py-4 lg:py-0">
            <div onClick={() => onSubmitHandler()} className="cursor-pointer">
              <ButtonSubmit />{" "}
            </div>
          </div>
        </form>
      </>
    );
  };
  return renderForm();
};

export default withRouter(StaySearchForm);
