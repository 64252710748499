import React, { useState } from "react";
import TravelLogo1 from "images/aboutUs/partner1.png";
import TravelLogo2 from "images/aboutUs/partner2.png";
import { Link } from "react-router-dom";

const TravelHelpCenter = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const travelers = [
    {
      id: 1,
      title: "Handpicked Selection",
      content:
        "Immerse yourself in a curated collection of luxury homes that go beyond accommodation – they are destinations in themselves. From panoramic views to exquisite interiors, our listings redefine the meaning of a perfect stay.",
      img: require("images/aboutUs/handpicked.png"),
    },
    {
      id: 1,
      title: "Personalized Experiences",
      content:
        "Your journey is unique, and so should be your stay. Discover personalized touches, local recommendations, and amenities that cater to your every need.",
      img: require("images/aboutUs/experience.png"),
    },
    {
      id: 1,
      title: "Secure Booking",
      content:
        "Book with confidence knowing that every transaction on Duomo is secure. Your safety and satisfaction are our top priorities.Whether you're a homeowner looking to share your haven or atraveler seeking the pinnacle of luxury, Duomo is the bridgebetween dreams and reality. Join us and make every stay a celebration of theextraordinary.",
      img: require("images/aboutUs/secure.png"),
    },
  ];
  return (
    <div>
      {/* <div className="bg-[#FCFBFF]">
        <div>
          <div className="hidden">
            {travelers?.map((item, index) => (
              <div className="flex flex-col items-center pt-16" key={index}>
                <div className="w-[50%] md:w-[25%]">
                  <img src={item?.img} alt="" />
                </div>
                <div className="w-[90%] md:w-[75%] pt-8">
                  <h2 className="text-[30px] font-[600] text-center">
                    {item?.title}
                  </h2>
                  <p className="pt-5 text-[15px] font-[400] leading-[25px] text-center">
                    {item?.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className=" mx-[3%] lg:mx-[3%] 2xl:mx-[10%] flex flex-col lg:flex-row relative">
        <div className="w-full  relative">
          <h2 className="font-[700] pt-10 pb-5 text-[22px]">
            Looking for something else?
          </h2>
          <div className="bg-[#FCFBFF] border border-[#E7E7E7] px-4 lg:px-10 py-5">
            <div className="flex flex-col lg:flex-row items-end">
              <div className="w-full my-1 lg:w-1/2 flex items-start">
                <div className="w-[5%]">
                  <img
                    src={require("images/chat11.png")}
                    alt=""
                    className="h-6"
                  />
                </div>
                <div className="px-2">
                  <h4 className="font-[600] text-[16px] text-[#26232A]">
                    Send us a message
                  </h4>
                  <p className="font-[400] text-[14px] pt-1 text-[#26232A]">
                    Contact our agents about your query, and we'll reply as soon
                    as possible.
                  </p>
                </div>
              </div>
              <div className="w-full my-1 lg:w-1/2  flex items-start">
                <Link to="/contact-us" className="w-full">
                  <button
                    type="submit"
                    className="w-full text-white bg-[#612ACE] hover:opacity-[0.9] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-[16px] px-4 md:px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Contact Us
                  </button>
                </Link>
              </div>
            </div>
            {/* <div className="my-4">
              <Link to="/contact-us">
                <button
                  type="submit"
                  className="w-full text-white bg-[#612ACE] hover:opacity-[0.9] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-[16px] px-4 md:px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Contact Us
                </button>
              </Link>
            </div> */}
            {/* <p className="font-[600] text-[15px] py-5 text-[#fff] text-[15px]">
              Embark on a journey where luxury meets comfort at Duomo.
            </p>
            <p className="font-[400] text-[15px] text-[#fff] text-[15px]">
              We curate acollection of exceptional properties that redefine the
              way you experiencetravel. Each listing on our platform is a
              testament to the commitment of ourhosts to create unforgettable
              moments for our guests.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelHelpCenter;
