import React from "react";
import PropertyCardsSections from "components/PropertyOwner/PropertyCardsSection";
import PropertyHero from "components/PropertyOwner/PropertyHero";
// import ServicesRentalReturns from "components/Services/ServicesRentalReturns";
import Faqs from "shared/Faq/Faq";

const PropertyOwner = () => {
  return (
    <div>
      <PropertyHero />
      <PropertyCardsSections />
      {/* <ServicesRentalReturns /> */}
      <div className="mx-[3%] lg:mx-[6%] xl:mx-[9%] 2xl:mx-[11%] pt-20 pb-20">
        <Faqs faqPage="Corporate" />
      </div>
    </div>
  );
};

export default PropertyOwner;
