import React from "react";
import { Link } from "react-router-dom";

const handleFacebook = () => {
  window.open(
    "https://www.facebook.com/profile.php?id=61554947276675",
    "_blank"
  );
};
const handleInstgram = () => {
  window.open("https://www.instagram.com/myduomo", "_blank");
};
const handleLinkedin = () => {
  window.open("https://www.linkedin.com/company/duomotourism", "_blank");
};
const handleTiktok = () => {
  window.open("https://www.tiktok.com/@myduomo?_t=8im50nOox3I&_r=1", "_blank");
};

const Footer: React.FC = () => {
  return (
    <div className="nc-Footer relative pt-16 pb-12 bg-[#131214]">
      <div className="mx-[3%] lg:mx-[1%] xl:mx-[5%] 2xl:mx-[5%] ">
        <div className="mx-[3%] lg:mx-[7%] 2xl:mx-[15%] flex md:flex-row flex-col justify-between">
          <div className="flex flex-col justify-between">
            <div className="mb-8">
              <img
                src={require("images/logo.png")}
                alt=""
                className="h-[41px]"
              />
            </div>
            <div className="mb-8">
              <h6 className="text-[14px] font-semibold leading-[21px] text-[#fff] roboto-font">
                Address:
              </h6>
              <p className="text-[14px] font-normal leading-[21px] text-[#fff] roboto-font mt-1">
                Barsha Heights, Dubai, United Arab Emirates
              </p>
            </div>
            <div className="mb-8">
              <h6 className="text-[14px] font-semibold leading-[21px] text-[#fff] roboto-font">
                Contact:
              </h6>
              <a href="callto:+971 50 1510743">
                <p className="text-[14px] font-normal leading-[21px] text-[#fff] roboto-font mt-1 underline cursor-pointer">
                  +971 50 1510743
                </p>
              </a>
              <a href="mailto:info@myduomo.com">
                <p className="text-[14px] font-normal leading-[21px] text-[#fff] roboto-font mt-1 underline cursor-pointer">
                  info@myduomo.com
                </p>
              </a>
            </div>
            <div className="flex">
              <div>
                <img
                  src={require("images/facebook1.png")}
                  alt=""
                  className="h-[20px] cursor-pointer"
                  onClick={handleFacebook}
                />
              </div>
              <div className="ml-4">
                <img
                  src={require("images/insta1.png")}
                  alt=""
                  className="h-[18px] cursor-pointer"
                  onClick={handleInstgram}
                />
              </div>
              <div className="ml-4">
                <img
                  src={require("images/lind1.png")}
                  alt=""
                  className="h-[18px] cursor-pointer"
                  onClick={handleLinkedin}
                />
              </div>
              <div className="ml-4 flex items-center">
                <img
                  src={require("images/vectors/tiktok.png")}
                  alt=""
                  className="h-[18px] cursor-pointer"
                  onClick={handleTiktok}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center md:mt-0 mt-10">
            <ul className="mr-20">
              <Link to="/about-us">
                <li className="text-[16px] font-medium leading-[24px] text-[#fff] roboto-font mb-3 ">
                  About Duomo
                </li>
              </Link>
              <Link to="/property-owner">
                <li className="text-[16px] font-medium leading-[24px] text-[#fff] roboto-font my-3">
                  List Your Home
                </li>
              </Link>
              <Link to="/cancellation-policy">
                <li className="text-[16px] font-medium leading-[24px] text-[#fff] roboto-font my-3">
                  Cancellation Policy
                </li>
              </Link>
              <Link to="/policy">
                <li className="text-[16px] font-medium leading-[24px] text-[#fff] roboto-font my-3">
                  Privacy Policy
                </li>
              </Link>
              <Link to="/contact-us">
                <li className="text-[16px] font-medium leading-[24px] text-[#fff] roboto-font my-3">
                  Contact Us
                </li>
              </Link>
              <Link to="/help-center">
                <li className="text-[16px] font-medium leading-[24px] text-[#fff] roboto-font mt-3">
                  Help Center
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-https://files.slack.com/files-pri/T04D33SVCNN-F06BABE4ZGT/image.png[3%] lg:mx-[1%] xl:mx-[5%] 2xl:mx-[5%] md:mt-16 mt-10 ">
        <div className="flex justify-between md:flex-row flex-col border-t  mx-[3%] lg:mx-[7%] 2xl:mx-[15%]">
          <div className="pt-8 text-white md:w-[70%] w-full  text-[13px]">
            © 2024 Duomo Tourism LLC, All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
