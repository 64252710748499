import React from "react";
import PropertyHero from "components/ListYourProperty/PropertyHero";
import Logos from "components/ListYourProperty/Logos";
import HowItWorks from "components/ListYourProperty/HowItWorks";
import Listings from "components/ListYourProperty/listings";
// import Reviews from "components/ListYourProperty/Reviews";
import FAQ from "components/ListYourProperty/Faq";

const PropertyOwner = () => {
  return (
    <div>
      <PropertyHero />
      <HowItWorks />
      <Listings />
      {/* <Reviews /> */}
      <Logos />
      <FAQ />
      {/* <PropertyCardsSections /> */}
      {/* <ServicesRentalReturns /> */}
      {/* <div className="mx-[3%] lg:mx-[6%] xl:mx-[9%] 2xl:mx-[11%] pt-20 pb-20">
        <Comment />
      </div> */}
    </div>
  );
};

export default PropertyOwner;
