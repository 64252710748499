import React, { FC, useEffect, useRef, useState } from "react";
import OurServicesMain from "../../components/ContactUs/OurServices";
import { withRouter } from "react-router";

const OurServics = (props: any) => {
  const ref: any = useRef(null);
  const { history } = props;
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  useEffect(() => {
    if (history?.location?.search?.split("=")[1] === "true") {
      handleClick();
    }
  }, [history?.location?.search?.split("=")[1]]);
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id="PageAbout"
    >
      <div>
        <OurServicesMain />
      </div>
    </div>
  );
};

export default withRouter(OurServics);
