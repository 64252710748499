import React, { useState } from "react";
import TravelLogo1 from "images/aboutUs/partner1.png";
import TravelLogo2 from "images/aboutUs/partner2.png";

const TravelAboutUs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const travelers = [
    {
      id: 1,
      title: "Handpicked Selection",
      content:
        "Immerse yourself in a curated collection of luxury homes that go beyond accommodation – they are destinations in themselves. From panoramic views to exquisite interiors, our listings redefine the meaning of a perfect stay.",
      img: require("images/aboutUs/handpicked.png"),
    },
    {
      id: 1,
      title: "Personalized Experiences",
      content:
        "Your journey is unique, and so should be your stay. Discover personalized touches, local recommendations, and amenities that cater to your every need.",
      img: require("images/aboutUs/experience.png"),
    },
    {
      id: 1,
      title: "Secure Booking",
      content:
        "Book with confidence knowing that every transaction on Duomo is secure. Your safety and satisfaction are our top priorities.Whether you're a homeowner looking to share your haven or a traveler seeking the pinnacle of luxury, Duomo is the bridge between dreams and reality. Join us and make every stay a celebration of the extraordinary.",
      img: require("images/aboutUs/secure.png"),
    },
  ];
  return (
    <div>
      <div className=" py-20 mb-10">
        <div>
          <div>
            <h4 className="pe-4 text-[23px] font-[600] text-[#4248ED] pt-2 text-center">
              Our Commitments to Travelers
            </h4>
          </div>
          {/* links */}
          <div className="hidden lg:block">
            <div className="flex justify-center pt-12">
              <div className="mx-[15%] xl:mx-[22%] aboutus-list-container 2xl:px-10">
                <ul className="flex items-center justify-between space-x-10">
                  <li
                    className={`text-[19px] cursor-pointer text-[#000000] border-b-[4px] pb-3 hover:border-[#4248ED] hover:font-[600]
                  ${
                    selectedTab === 0
                      ? "font-[600] border-[#4248ED]"
                      : "font-[400] border-[#FCFBFF]"
                  }
                  `}
                    onClick={() => setSelectedTab(0)}
                  >
                    Handpicked Selection
                  </li>
                  <li
                    className={`text-[19px] cursor-pointer text-[#000000] border-b-[4px] pb-3 hover:border-[#4248ED] hover:font-[600]
                  ${
                    selectedTab === 1
                      ? "font-[600] border-[#4248ED]"
                      : "font-[400] border-[#FCFBFF]"
                  }
                  `}
                    onClick={() => setSelectedTab(1)}
                  >
                    Personalized Experiences
                  </li>
                  <li
                    className={`text-[19px] cursor-pointer text-[#000000] border-b-[4px] pb-3 hover:border-[#4248ED] hover:font-[600]
                  ${
                    selectedTab === 2
                      ? "font-[600] border-[#4248ED]"
                      : "font-[400] border-[#FCFBFF]"
                  }
                  `}
                    onClick={() => setSelectedTab(2)}
                  >
                    Secure Booking
                  </li>
                </ul>
                {/* content */}
                <div className="flex pt-16">
                  <div className="w-[25%]">
                    <img src={travelers[selectedTab]?.img} alt="" />
                  </div>
                  <div className="w-[75%] pl-12 pt-5">
                    <h2 className="text-[30px] font-[600]">
                      {travelers[selectedTab]?.title}
                    </h2>
                    <p className="pt-5 text-[15px] font-[400] leading-[25px]">
                      {travelers[selectedTab]?.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block lg:hidden">
            {travelers?.map((item, index) => (
              <div className="flex flex-col items-center pt-16" key={index}>
                <div className="w-[50%] md:w-[25%]">
                  <img src={item?.img} alt="" />
                </div>
                <div className="w-[90%] md:w-[75%] pt-8">
                  <h2 className="text-[30px] font-[600] text-center">
                    {item?.title}
                  </h2>
                  <p className="pt-5 text-[15px] font-[400] leading-[25px] text-center">
                    {item?.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="bg-[#4F46E5] px-10 lg:px-20 mx-[3%] lg:mx-[3%] 2xl:mx-[15%] flex flex-col lg:flex-row relative">
        <div className="w-full lg:w-[60%] xl:w-[70%] py-10 xl:py-12 2xl:py-14 relative lg:pr-12">
          <h2 className="font-[700] text-[#fff] text-[22px]">For Travelers</h2>
          <p className="font-[600] text-[15px] py-5 text-[#fff] text-[15px]">
            Embark on a journey where luxury meets comfort at Duomo.
          </p>
          <p className="font-[400] text-[15px] text-[#fff] text-[15px]">
            We curate acollection of exceptional properties that redefine the
            way you experiencetravel. Each listing on our platform is a
            testament to the commitment of ourhosts to create unforgettable
            moments for our guests.
          </p>
        </div>
        <div className="w-full lg:w-[40%] xl:w-[30%] relative about-gift-img flex justify-center lg:justify-start pb-10 lg:pb-0">
          <img src={require("images/aboutUs/gifts.png")} className="lg:absolute img-about-us h-[300px] lg:h-[120%]" alt="" />
        </div>
      </div> */}
    </div>
  );
};

export default TravelAboutUs;
