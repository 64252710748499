import React, { FC } from "react";
import Carousel from "react-multi-carousel";
const CardCategory3 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1299 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1298, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 701 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 2,
    }
  };

  return (
    <div className="py-28 mx-[2%] sm:mx-[5%] lg:mx-[10%]">
      {/* <div className="flex flex-wrap justify-between logos-container-listProperty"> */}
        <Carousel
          responsive={responsive}
          arrows={false}
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={2500}
          className="flex flex-wrap justify-between  logos-container-listProperty"
        >
          <div className="flex justify-center img-container">
            <img
              alt=""
              src={require("images/listProperty/Airbnb.png")}
              className="h-6 sm:h-8 lg:h-10 xl:h-12"
            />
          </div>
          <div className="flex justify-center img-container">
            <img
              alt=""
              src={require("images/listProperty/booking.png")}
              className="h-6 sm:h-7 lg:h-8 xl:h-10 xl:mt-2 mt-1"
            />
          </div>
          <div className="flex justify-center img-container">
            <img
              alt=""
              src={require("images/listProperty/homeaway.png")}
              className="h-6 sm:h-7 lg:h-9 xl:h-11"
            />
          </div>
          <div className="flex justify-center img-container">
            <img
              alt=""
              src={require("images/listProperty/TripAdvisor.png")}
              className="h-6 sm:h-7 lg:h-9 xl:h-11"
            />
          </div>
          <div className="flex justify-center img-container">
            <img
              alt=""
              src={require("images/listProperty/VRBO.png")}
              className="h-5 sm:h-6 lg:h-8 xl:h-10"
            />
          </div>
          <div className="flex justify-center img-containerr">
            <img
              alt=""
              src={require("images/listProperty/Expedia.png")}
              className="h-5 sm:h-6 lg:h-8 xl:h-10 xl:mt-2 mt-1"
            />
          </div>
        </Carousel>
      {/* </div> */}
    </div>
  );
};

export default CardCategory3;
