import clientSay1 from "images/about-homepage.png";
import LazyLoad from "react-lazy-load";
const AboutHeroimg = () => {
  return (
    <div className="w-full">
      <div>
        <LazyLoad offset={300}>
          <img className="w-full" src={clientSay1} alt="" loading="lazy" />
        </LazyLoad>
      </div>
      <div className="w-full h-full flex items-center justify-center">
        <div className="bg-[#fff] px-4 sm:px-6 lg:px-8 pt-12  rounded-[6px] w-full sm:w-3/4 md:w-2/3 lg:w-[60%] 2xl:w-[50%] relative z-10 md:bottom-[130px] flex flex-col justify-center">
          <div>
            <h1 className="text-[44px] font-[600] text-center">About Us</h1>
          </div>
          <div>
            <p className="text-[15px] font-[400] pt-10">
              Experience luxury and beyond, where indulgence knows no limit. We
              hold the belief that every getaway should be a flawless escape—a
              seamless fusion of comfort, luxury, and the unparalleled beauty of
              your chosen destination. Specializing in delivering unique and
              unforgettable holiday home experiences that transcend the
              ordinary, our team of professionals offers bespoke solutions to
              ensure you receive the highest quality service. Your property is
              guaranteed to be excellently managed and maintained seamlessly
              throughout the contract term.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHeroimg;
