import { useContext, useEffect } from "react";
import { GlobalContext } from "context/globalContext/globalContext";
import { Switch, Route, withRouter } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer";
import { GetAPIService } from "context/services";
// import { GlobalContext } from "context/globalContext/globalContext";
// duomo pages
import Homepage from "containers/Homepage/PageHome";
import ListingStay from "containers/ListingStayMap/ListingStayMapPage";
import AboutPage from "containers/AboutPage/AboutPage";
// import BlogMain from "containers/Blog/BlogMain";
import FAQ from "containers/Faqs/Faqs";
// all pages
// import PageHome from "containers/PageHome/PageHome";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
// import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
// import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
// import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
// import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
// import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
// import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
// import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
// import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
// import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
// import PayPage from "containers/PayPage/PayPage";
// import AuthorPage from "containers/AuthorPage/AuthorPage";
// import AccountPage from "containers/AccountPage/AccountPage";
// import AccountPass from "containers/AccountPage/AccountPass";
// import AccountSavelists from "containers/AccountPage/AccountSavelists";
// import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
// import PageAbout from "containers/PageAbout/PageAbout";
// import PageSignUp from "containers/PageSignUp/PageSignUp";
// import PageLogin from "containers/PageLogin/PageLogin";
// import PageSubcription from "containers/PageSubcription/PageSubcription";
// import BlogPage from "containers/BlogPage/BlogPage";
// import BlogSingle from "containers/BlogPage/BlogSingle";
// import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
// import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
// import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
// import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
// import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
// import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
// import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
// import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
// import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
// import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import TermsAndConditions from "containers/TermsAndConditions";
import Policy from "containers/Policy";
// import Authentication from "containers/Authentication";
import Services from "containers/Services/Services";
// import BlogDetails from "containers/BlogDetails/BlogSingle";
import PropertyOwner from "containers/PropertyOwner/PropertyOwner";
import ListProperty from "containers/ListYourProperty/ListYourProperty";
import CancelationPolicy from "containers/CancelationPolicy";
import HelpCenter from "containers/HelpCenter/HelpCenterPage";
import ContactUs from "containers/ContactUs/Page";

export const pages: Page[] = [
  // duomo routes
  { path: "/", exact: true, component: Homepage },
  { path: "/listings", exact: true, component: ListingStay },
  { path: "/about-us", exact: true, component: AboutPage },
  { path: "/services", component: Services },
  // { path: "/blog-details", component: BlogDetails },
  { path: "/corporate-partner", component: PropertyOwner },
  // { path: "/blog-page", component: BlogMain },
  { path: "/faq", component: FAQ },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/policy", component: Policy },
  { path: "/contact", component: PageContact },
  { path: "/property-owner", component: ListProperty },
  { path: "/cancellation-policy", component: CancelationPolicy },
  { path: "/help-center", component: HelpCenter },
  { path: "/contact-us", component: ContactUs },
  // all other routes
  // { path: "/home", exact: true, component: PageHome },
  // { path: "/#", exact: true, component: PageHome },
  { path: "/listing-stay", component: ListingStayPage },
  // { path: "/listing-stay-map", component: ListingStayMapPage },
  // { path: "/listing-stay-detail", component: ListingStayDetailPage },
  //
  /*{
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },
  //
  { path: "/listing-car", component: ListingCarPage },
  { path: "/listing-car-map", component: ListingCarMapPage },
  { path: "/listing-car-detail", component: ListingCarDetailPage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //

  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/auth", component: Authentication },*/
];

const Routes = ({ history }: any) => {
  const { dispatch } = useContext(GlobalContext);
  // useEffect for API call to get all room listings
  useEffect(() => {
    GetAPIService(
      "/proxy/listings?include_related_objects=10&page=1&per_page=10"
    ).then((response: any) => {
      dispatch({
        type: "SET_ALL_ROOM_LISTINGS",
        payload: response,
      });
      dispatch({
        type: "SET_LOADER",
        payload: false,
      });
    });
  }, []);
  return (
    <div>
      <div
        className="fixed bottom-5 right-5 z-[200] cursor-pointer"
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send?phone=971501510743&text=",
            "_blank"
          )
        }
      >
        <img src={require("images/whatsapp.png")} alt="" className="h-14" />
      </div>
      <ScrollToTop />
      {/* shadow */}
      {history?.location.pathname === "/" && <div className="bg-shadow1" />}
      {/* header */}
      <Header />
      {/* all routes */}
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>

      <Footer />
    </div>
  );
};

export default withRouter(Routes);
