import React, { Fragment, useState, useEffect } from "react";
import { PostAPIService } from "context/services";
import { Dialog, Transition } from "@headlessui/react";
import { notification } from "antd";
// import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
// import Checkbox from "shared/Checkbox/Checkbox";

const TabFilters = ({ isOpenMoreFilter, setisOpenMoreFilter }: any) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message: any, description: any) => {
    api.open({
      message: message,
      description: description,
      duration: 0,
    });
  };
  const [user, setUser]: any = useState({
    name: "",
    email: "",
    number: "",
    property_details: "",
    property_location: "",
    message: "",
  });
  const [initialUser, setInitialUser] = useState({ ...user });
  const [loader, setLoader] = useState(false);
  const [terms, setTerms] = useState(false);
  useEffect(() => {
    if (isOpenMoreFilter) {
      setInitialUser({ ...user });
    }
  }, [isOpenMoreFilter]);
  const onChangeHandler = (key: any, value: any) => {
    let duplicate: any = { ...user };
    duplicate[key] = value;
    setUser(duplicate);
  };
  // handler payment
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    const payload = {
      full_name: user?.name,
      email: user?.email,
      phone_number: user?.number,
      property_details: user?.property_details,
      property_location: user?.property_location,
      contact_message: user?.message,
    };
    setLoader(true);
    PostAPIService("/form/refer/submit", payload)
      .then((response) => {
        console.log("response", response);
        setLoader(false);
        setUser({
          name: "",
          email: "",
          number: "",
          property_details: "",
          property_location: "",
          message: "",
        });
        setTerms(false);
        setUser({ ...initialUser });
        openNotification(
          "Success",
          "Thank You our team will get back to you at the earliest."
        );
      })
      .catch((error) => {
        console.log("response", error);
        setLoader(false);
        openNotification("Error", "");
      });
  };
  const handleCheckboxChange = () => {
    setTerms(!terms);
  };
  const closeModalMoreFilter = () => {
    setUser({ ...initialUser });
    setisOpenMoreFilter(false);
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center px-5">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-3xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl ">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    {contextHolder}
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Refer a Friend
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>
                  <form onSubmit={(e) => onSubmitHandler(e)}>
                    <div className="flex-grow overflow-y-auto">
                      <div className="px-10">
                        <div className="py-5">
                          <div className="">
                            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                              Name*
                            </label>
                            <input
                              className="  border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:text-white"
                              placeholder="Name"
                              type="text"
                              required
                              value={user?.name}
                              onChange={(e) =>
                                onChangeHandler("name", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                              Email Address*
                            </label>
                            <input
                              className="  border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              placeholder="Email Address"
                              type="email"
                              required
                              value={user?.email}
                              onChange={(e) =>
                                onChangeHandler("email", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="py-5">
                          <div className="">
                            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                              Phone Number*
                            </label>
                            <input
                              className="  border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              placeholder="Phone Number"
                              type="number"
                              required
                              value={user?.number}
                              onChange={(e) =>
                                onChangeHandler("number", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                              Property Details*
                            </label>
                            <input
                              className="  border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2  dark:border-gray-500  dark:text-white"
                              placeholder="Property details"
                              type="text"
                              required
                              value={user?.property_details}
                              onChange={(e) =>
                                onChangeHandler(
                                  "property_details",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="">
                            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                              Property Location*
                            </label>
                            <input
                              className="  border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              placeholder="Property location"
                              type="text"
                              required
                              value={user?.property_location}
                              onChange={(e) =>
                                onChangeHandler(
                                  "property_location",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="py-5">
                          <div className="">
                            <label className="block mb-1 md:mb-2 text-sm font-[400] text-[#26232A] dark:text-white">
                              Message
                            </label>
                            <textarea
                              rows={5}
                              className="  border border-gray-300 font-[400] text-[#26232A] text-sm rounded block w-full p-1.5 md:p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              placeholder="Write something"
                              typeof="text"
                              value={user?.message}
                              onChange={(e) =>
                                onChangeHandler("message", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="pb-5">
                          <label className="!text-sm !font-[400] !text-[#26232A] !dark:text-white">
                            <input
                              type="checkbox"
                              className="mr-1"
                              checked={terms}
                              onClick={handleCheckboxChange}
                            />
                            <span className="ml-1">
                              I accept the Terms and Conditions
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                      <ButtonThird
                        onClick={closeModalMoreFilter}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        Cancel
                      </ButtonThird>
                      <ButtonPrimary
                        disabled={loader}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        Submit
                      </ButtonPrimary>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return <div className="flex lg:space-x-4">{renderTabMoreFilter()}</div>;
};

export default TabFilters;
