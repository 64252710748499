import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/homePage/address.png";
import HIW2img from "images/homePage/user.png";
import HIW3img from "images/homePage/hom.png";
// import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
}

const DEMO_DATA = [
  {
    id: 1,
    img: HIW1img,
    title: "Get inspired for your next trip.",
    desc: "Explore a diverse selection of vacation homes from studios, apartments, townhouses, villas, and everything in between.",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Customized services to meet your requirements.",
    desc: "Discover our range of customized offerings including cleaning, maintenance and concierge.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Experience the comfort of your home.",
    desc: "Enjoy the lifestyle of comfort and experience the coziness of your own living space with Duomo",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHowItWork boxshadow1 rounded-2xl py-7 px-7 my-24 ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <h2 className="block mt-5 text-[#262626] font-[400] text-[18px] text-center md:text-left pb-2">
        Find the best vacation homes
      </h2>
      <div className="mt-5 relative grid md:grid-cols-3 gap-10">
        {/* <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        /> */}
        {DEMO_DATA.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center md:items-start md:max-w-sm h-full"
          >
            <NcImage
              containerClassName="mb-3 max-w-[50px] !h-[36px]"
              src={item.img}
            />
            <div className="text-center md:text-left">
              <h3 className="text-[16px] text-[#262626] font-[600]">
                {item.title}
              </h3>
              <span className="block mt-5 text-[#262626] text-[14px]">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
