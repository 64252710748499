import React, { FC } from "react";
import rightImg1 from "images/vacation1.png";
import rightImg2 from "images/vacation2.png";
import rightImg3 from "images/vacation3.png";
import rightImg4 from "images/vacation4.png";
import rightImg5 from "images/vacation5.png";
// import rightImg2 from "images/homePage/Card-05.png";
// import rightImg3 from "images/homePage/Card-06.png";
import { Link } from "react-router-dom";
// import Badge from "shared/Badge/Badge";
import Carousel from "react-multi-carousel";
import NcImage from "shared/NcImage/NcImage";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImg1,
}) => {
  const renderPostRelated = (className: any, img?: any, text?: any) => {
    return (
      <div
        className={`relative aspect-w-3 rounded-[6px] overflow-hidden group cursor-pointer ${className}`}
        onClick={() =>
          window.open(
            "https://duomo.hostify.club/listings?start_date=&end_date=&bedrooms=&guests=",
            "_blank"
          )
        }
      >
        {/* <Link to={"/blog-details"} /> */}
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={
            img
              ? img
              : "https://images.pexels.com/photos/3935702/pexels-photo-3935702.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
          loading="lazy"
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-6">
          <div>
            <h2 className="block text-[#fff] font-[700] text-[15px]">
              <span className="line-clamp-2">{text}</span>
            </h2>
          </div>
        </div>
        {/* <Link to={"/blog-details"} /> */}
      </div>
    );
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="bg-SectionExpertise2 rounded-[10px]">
      <div
        className={`nc-SectionBecomeAnAuthor bg-[rgba(0,0,0,0.2)] relative flex flex-col justify-between items-start rounded-[10px] ${className} `}
        data-nc-id="SectionBecomeAnAuthor"
      >
        <div className="w-full lg:w-[60%] px-8">
          <h2 className="font-[500] text-3xl sm:text-[36px] xl:text-[43px] mt-6 sm:mt-11 text-white !leading-[54px] text-left ">
            Find vacation rentals with all the comforts of home: Dubai’s hottest
            spots.
          </h2>
        </div>
        <div className="flex-grow pt-40 lg:pt-60 pb-3 w-full px-4">
          <div className="relative rounded-[10px] bg-white pl-2 py-2">
            <Carousel responsive={responsive}>
              {renderPostRelated("h-[200px] mr-2", rightImg1, "Downtown Dubai")}
              {renderPostRelated("h-[200px] mr-2", rightImg2, "Jumeirah Beach")}
              {renderPostRelated("h-[200px] mr-2", rightImg3, "Palm Jumeirah")}
              {renderPostRelated(
                "h-[200px] mr-2",
                rightImg4,
                "Jumeirah Lake Towers"
              )}
              {renderPostRelated("h-[200px] mr-2", rightImg5, "Jumeirah & JLT")}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
