import React, { FC, useState } from "react";
import Logo from "shared/Logo/Logo";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Filter from "containers/ListingStayMap/Filters";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { withRouter } from "react-router-dom";

const MainNav1 = (props: any) => {
  const { isTop, history }: any = props;
  const [filter, setFilter] = useState(false);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  return (
    <>
      {/* filters */}
      <Filter
        isOpenMoreFilter={isOpenMoreFilter}
        setisOpenMoreFilter={setisOpenMoreFilter}
      />
      {/*  */}
      <div
        className={`nc-MainNav1 relative z-[40]  ${
          isTop ? "onTop " : "notOnTop backdrop-filter"
        } `}
      >
        <div
          className={`absolute w-full left-0 bg-white border-b border-[#E6E8EC] transition-all ease-in-out delay-150 duration-400 ${
            filter ? "z-[40] pt-4 pb-32" : "py-4"
          }`}
        >
          <div className="mx-[3%] flex justify-between">
            <div className="flex justify-start items-center space-x-4 sm:space-x-10 2xl:space-x-14 z-[50] relative">
              <Logo />
            </div>
            <div className="flex items-center justify-start text-neutral-700 dark:text-neutral-100 ">
              {/* default filter */}
              <div>
                <div className="absolute left-0 right-0 hidden lg:flex justify-center top-[18px]">
                  <div
                    className={`transition ease-in-out bg-white delay-150 duration-300 flex items-center justify-between cursor-pointer ${
                      filter ? `z-40` : `z-40`
                    }`}
                    style={{
                      transform: filter ? `scale(0)` : `scale(1)`,
                    }}
                  >
                    <div
                      className={`border border-[#E0E0E0] rounded-full px-5 py-[10px] flex items-center justify-between cursor-pointer`}
                      onClick={() => setFilter(true)}
                    >
                      <div className=" text-[16px] flex items-center w-[105px]">
                        <i className="las la-search rotate-[-90deg]"></i>{" "}
                        <span className="pl-[6px]">Map Area</span>
                      </div>
                      <div className=" text-[14px] flex items-center w-[1px] h-[20px] bg-[#E0E0E0] mx-4"></div>
                      <div className=" text-[16px] flex items-center w-[105px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 mr-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          />
                        </svg>
                        <span className="pl-[6px]">Anytime</span>
                      </div>
                      <div className=" text-[14px] flex items-center w-[1px] h-[20px] bg-[#E0E0E0] mx-4"></div>
                      <div className=" text-[16px] flex items-center w-[105px]">
                        <i className=" las la-user text-[18px]"></i>
                        <span className="pl-[6px]">Guests</span>
                      </div>
                    </div>
                    <div
                      className="bg-[#EDEDFF] py-[10px] pl-4 pr-4 mx-2 rounded-full flex items-center justify-center"
                      onClick={() => {
                        setisOpenMoreFilter(true);
                      }}
                    >
                      <img
                        src={require("images/filter.png")}
                        className="h-4 mr-[-1px]"
                        alt=""
                      />
                      <span className="text-[16px] text-[#612ACE]">Filter</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* search to show when click on active filter */}
              <div
                className={`${
                  filter ? `z-40` : `z-10`
                } w-full hidden lg:block lg:absolute left-0 transition ease-in-out delay-100 duration-400 pb-4 top-[60px] lg:top-[45px] xl:top-[35px]`}
                onClick={() => setFilter(false)}
              >
                <div
                  className="transition ease-in-out delay-150 duration-300 pb-4"
                  style={{
                    transform: filter ? `scale(1)` : `scale(0)`,
                  }}
                >
                  <div className="mx-auto container hero-form-container">
                    <HeroSearchForm currentPage="Stay" currentTab="Stay"  />
                  </div>
                </div>
              </div>
              {/* hamburger */}
              <div className="flex items-center z-40">
                <MenuBar />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bg color div */}
      <div
        onClick={() => setFilter(false)}
        className={`hidden lg:block transition ease-in-out delay-150 duration-400 ${
          filter
            ? `bg-[rgba(0,0,0,0.4)] h-[100vh] z-30 opacity-[1]`
            : `opacity-[0]`
        } w-full absolute`}
      ></div>
    </>
  );
};

export default withRouter(MainNav1);
