import React, { FC } from "react";
import AboutHeroimg from "components/AboutPage/AboutHeroimg";
import TravelAboutUs from "components/AboutPage/TravelAboutUs";
import Travelers from "components/AboutPage/Travelers";

export interface PageAboutProps {
  className?: string;
}
const AboutPage: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <AboutHeroimg />
      <TravelAboutUs />
      <Travelers />
    </div>
  );
};

export default AboutPage;
